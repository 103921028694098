export const Loading = () => {
    return (
        <div id="status">
            <div className="spinner-chase">
                <div className="chase-dot"/>
                <div className="chase-dot"/>
                <div className="chase-dot"/>
                <div className="chase-dot"/>
                <div className="chase-dot"/>
                <div className="chase-dot"/>
            </div>
        </div>
    )
}
