import React from "react";
import { v4 as uuidv4 } from "uuid";
import { NuevoItemFin } from '../../../interfaces/estilo';

interface AdminThemeGoodBye {
    isAddNewGoodBye: boolean,
    isUpdateGoodBye: boolean,
    listDataItem: NuevoItemFin[],
    selectItem: NuevoItemFin,
}

export const useThemeGoodBye = () => {
    const [{
        isAddNewGoodBye,
        isUpdateGoodBye,
        listDataItem,
        selectItem
    }, setAdminTheme] = React.useState<AdminThemeGoodBye>({
        isAddNewGoodBye: false,
        isUpdateGoodBye: false,
        listDataItem: [] as NuevoItemFin[],
        selectItem: {} as NuevoItemFin
    })

    const setDataItem = (dataItem: NuevoItemFin) => {
        setAdminTheme(prev => {
            return {...prev, selectItem: dataItem}
        })
    }

    const setListDataItem = (listItems: NuevoItemFin[]) => {
        setAdminTheme(prev => {
            return {...prev, listDataItem: listItems}
        })
    }

    const duplicateDataItem = (dataItem: NuevoItemFin) => {
        let newDataItem = {} as NuevoItemFin
        newDataItem.Id = uuidv4()
        newDataItem.Nombre = dataItem.Nombre + " copia"
        newDataItem.Texto = dataItem.Texto
        newDataItem.RangoInicio = dataItem.RangoInicio
        newDataItem.RangoFin = dataItem.RangoFin
        newDataItem.Textos = dataItem.Textos || {}

        let newListDataItem: NuevoItemFin[] = listDataItem
        newListDataItem.push(newDataItem)
        setListDataItem(newListDataItem)
    }

    const deleteDataItem = (dataItem: NuevoItemFin) => {
        const newListDataItem = listDataItem.filter((item: NuevoItemFin) => item.Id !== dataItem.Id)
        setListDataItem(newListDataItem)
    }

    const changeNew = (state: boolean) => {
        setAdminTheme(prev => {
            return {...prev, isAddNewGoodBye: state}
        });
    }

    const changeEdit = (state: boolean) => {
        setAdminTheme(prev => {
            return {...prev, isUpdateGoodBye: state}
        });
    }


    const addNewDataItem = (formData: NuevoItemFin) => {
        formData.Id = uuidv4()

        let newListDataItem: NuevoItemFin[] = listDataItem
        newListDataItem.push(formData)
        setListDataItem(newListDataItem)
        handleCloseAddUpdate()
    }

    const updateDataItem = (formData: NuevoItemFin) => {
        const updateListDataItem = listDataItem.map((item: NuevoItemFin) =>
            item.Id === formData.Id ?
                {
                    ...item,
                    ...formData
                } : item);
        setListDataItem(updateListDataItem)
        handleCloseAddUpdate()
    }

    const handleCloseAddUpdate = () => {
        changeNew && changeNew(false);
        changeEdit && changeEdit(false);
        setDataItem({} as NuevoItemFin)
    }

    return {
        isAddNewGoodBye,
        isUpdateGoodBye,
        selectItem,
        listDataItem,
        setDataItem,
        setListDataItem,
        duplicateDataItem,
        deleteDataItem,
        changeNew,
        changeEdit,
        addNewDataItem,
        updateDataItem
    }
}
