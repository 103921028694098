function init (Survey, $) {
  const iconId = "icon-microphone";
  Survey.SvgRegistry && Survey.SvgRegistry.registerIconFromSvg(iconId, require("svg-inline-loader?classPrefix!./images/microphone.svg"), "");
  $ = $ || window.$;
  var widget = {
    name: "speedtotext",
    title: "Speed to Text",
    iconName: iconId,
    isFit: function(question) {
      return question.getType() === "speedtotext";
    },
    htmlTemplate: `
      <div class="input-single">
        <textarea id="note-textarea" class="sd-input sd-comment stt-theme-comment sd-speedtotext-in" rows="6"></textarea>
      </div>
      <button id="start-record-btn" class="sd-action sd-speedtotext__btn" title="Start Recording">
        <i class="stt-icon-microphone"></i>
      </button>
    `,
    activatedByChanged: function(activatedBy) {
      Survey.JsonObject.metaData.addClass("speedtotext", [], null, "empty");
    },
    afterRender: function(question, el) {
      let textArea = el.getElementsByTagName("textarea")[0];
      textArea.id = "note-textarea-" + question.inputId
      let button = el.getElementsByTagName("button")[0];
      textArea.id = "start-record-btn-" + question.inputId
      textArea.value = question.value!==undefined?question.value:""
      if (question.isReadOnly) {
        textArea.setAttribute("disabled", true);
        button.style.display = "none";
      }
      try {
        let listening = false;
        let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        let recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.onerror = function(event) {
          if (event.error == "no-speech") {
            console.log("No speech was detected. Try again.");
          }
        };
        const start = () => {
          recognition.start();
          button.classList.add("stt-speak");
        };
        const stop = () => {
          recognition.stop();
          button.classList.remove("stt-speak");
        };
        const onResult = (event) => {
          textArea.value = "";
          for (const res of event.results) {
            let transcript = res[0].transcript;
            textArea.value += transcript;
          }
          question.value = textArea.value;
        };

        recognition.addEventListener("result", onResult);
        if (button) {
          button.addEventListener("click", () => {
            listening ? stop() : start();
            listening = !listening;
          });
        }
      } catch (e) {
        button.style.display = "none";
      }
      if (textArea) {
        textArea.addEventListener("change", (e) => {
          question.value = e.target.value;
        });
      }

      question.readOnlyChangedCallback = function() {
        if (question.isReadOnly) {
          textArea.setAttribute("disabled", true);
          button.style.display = "none";
        } else {
          textArea.setAttribute("disabled", false);
          button.style.display = "block";
        }
      };
    },
    willUnmount: function(question, el) {
      question.readOnlyChangedCallback = null;
    },
    pdfQuestionType: "dropdown",
  };
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
}

if (typeof Survey !== "undefined") {
  init(Survey, window.$);
}

export default init;
