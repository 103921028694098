import PropTypes from "prop-types";
import { useEffect, useState } from 'react';

//redux
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router';

//actions
import { changeLayout, changeLayoutWidth, changeTopbarTheme } from "../../store/actions"
import Footer from "./Footer";
import Header from "./Header";

//components
import Navbar from "./Navbar"

const HorizontalLayout = ({children, styleMenu}: any) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const {
        topbarTheme, layoutWidth
    } = useSelector((state: any) => ({
        topbarTheme: state.Layout.topbarTheme,
        layoutWidth: state.Layout.layoutWidth
    }))

    /*
    document title
    */
    useEffect(() => {
        const title = location.pathname
        let currentage = title.charAt(1).toUpperCase() + title.slice(2)

        document.title =
            currentage + " | Smartsurvey"
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        dispatch(changeLayout("horizontal"));
    }, [dispatch]);

    useEffect(() => {
        if (topbarTheme) {
            dispatch(changeTopbarTheme(topbarTheme));
        }
    }, [dispatch, topbarTheme]);

    useEffect(() => {
        if (layoutWidth) {
            dispatch(changeLayoutWidth(layoutWidth));
        }
    }, [dispatch, layoutWidth]);

    const [isMenuOpened] = useState(false);


    return (
        <>
            <div id="layout-wrapper" className={styleMenu !== undefined ? 'headerFixed' : 'showLeftControls'}>
                <Header/>
                <Navbar menuOpen={isMenuOpened} styleMenu={styleMenu}/>
                <div className={styleMenu !== undefined ? "main-content-survey-form" : "main-content-survey"}>
                    {children}
                </div>
                <Footer styleMenu={styleMenu}/>
            </div>
        </>
    );
}

HorizontalLayout.propTypes = {
    changeLayout: PropTypes.func,/*  */
    changeLayoutWidth: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any,
    styleMenu: PropTypes.any
}


export default HorizontalLayout;
