import { EstiloTexto } from "../../../interfaces/estilo"

const styleColor = document.documentElement.style

export const styleDefault = {
    backgroundNavigation: "#e5e5e5",
    backgroundNavigationCurrent: "#20c6c6",
    backgroundNavigationPassed: "#168b8b",
    fontsizeNavigationPassed: "14px",
    fontColorNavigation: "#ffffff",
    fontColorInactivoNavigation: "#000000",
    colorPreguntaBotonFondo: "#19b394",
    colorPreguntaBotonTexto: "#ffffff",
    colorCirculoAnimacionFin: "#3bc2ac",
    colorCheckAnimacionFin: "#ffffff",
    colorTextoAnimacionFin: "#000000",
    colorTransiciones: "#3bc2ac",
    imageAlignHeader: "center",
    colorTitlePage: "#000000",
    colorSubTitlePage: "#000000"
}

export const SetStyleCustom = (style: EstiloTexto) => {
    styleColor.setProperty('--background-header', style.colorHeader)
    styleColor.setProperty('--background', style.colorFondo)
    styleColor.setProperty('--background-cel-relleno', style.rellenoCelda)
    styleColor.setProperty('--color-tex-cont', style.colorTextoContenido)
    styleColor.setProperty('--color-border-cel', style.bordeCelda)
    styleColor.setProperty('--color-title', style.colorTitulo)
    styleColor.setProperty('--backgroundFondo', style.colorFondo)
    styleColor.setProperty('--background-btn', style.fondoBtn)
    styleColor.setProperty('--color-text-btn', style.coloreTextBtn)
    styleColor.setProperty('--background-btn-hover', style.fondoBtnHover)
    styleColor.setProperty('--font-family', style.fontFamily)
    styleColor.setProperty('--font-size', style.fontSize)
    styleColor.setProperty('--background-question', style.fondoQuestion)
    styleColor.setProperty('--color-title-header', style.colorTitleHeader)
    styleColor.setProperty('--color-subtitle-header', style.colorSubtitleHeader)
    styleColor.setProperty('--text-align-question', style.textAlignQuestion)
    styleColor.setProperty('--text-weight-question', style.textWeightQuestion)
    styleColor.setProperty('--text-decoration-question', style.textDecorationQuestion)
    styleColor.setProperty('--text-align-title-header', style.textAlignTitleHeader)
    styleColor.setProperty('--text-weight-title-header', style.textWeightTitleHeader)
    styleColor.setProperty('--text-decoration-title-header', style.textDecorationTitleHeader)
    styleColor.setProperty('--text-align-subtitle-header', style.textAlignSubTitleHeader)
    styleColor.setProperty('--text-weight-subtitle-header', style.textWeightSubTitleHeader)
    styleColor.setProperty('--text-decoration-subtitle-header', style.textDecorationSubTitleHeader)


    styleColor.setProperty('--background-one', style.backgroudColorInicio)
    styleColor.setProperty('--text-color-one-inv', style.colorInicioInvitado)
    styleColor.setProperty('--text-color-one-log', style.colorInicioLogeado)

    styleColor.setProperty('--text-align-one-inv', style.textAlignInicioInvitado)
    styleColor.setProperty('--text-decoration-one-inv', style.textDecorationInicioInvitado)
    styleColor.setProperty('--text-weight-one-inv', style.textWeightInicioInvitado)
    styleColor.setProperty('--text-align-one-log', style.textAlignInicioLogeado)
    styleColor.setProperty('--text-decoration-one-log', style.textDecorationInicioLogeado)
    styleColor.setProperty('--text-weight-one-log', style.textWeightInicioLogeado)

    styleColor.setProperty('--background-three', style.backgroudColorFin)
    styleColor.setProperty('--text-color-three-inv', style.colorFinInvitado)
    styleColor.setProperty('--text-color-three-log', style.colorFinLogeado)

    styleColor.setProperty('--text-align-three-inv', style.textAlignFinInvitado)
    styleColor.setProperty('--text-decoration-three-inv', style.textDecorationFinInvitado)
    styleColor.setProperty('--text-weight-three-inv', style.textWeightFinInvitado)
    styleColor.setProperty('--text-align-three-log', style.textAlignFinLogeado)
    styleColor.setProperty('--text-decoration-three-log', style.textDecorationFinLogeado)
    styleColor.setProperty('--text-weight-three-log', style.textWeightFinLogeado)

    styleColor.setProperty('--background-navigation', getValueByDefault(style.backgroundNavigation, styleDefault.backgroundNavigation))
    styleColor.setProperty('--background-navigation-current', getValueByDefault(style.backgroundNavigationCurrent, styleDefault.backgroundNavigationCurrent))
    styleColor.setProperty('--background-navigation-passed', getValueByDefault(style.backgroundNavigationPassed, styleDefault.backgroundNavigationPassed))
    styleColor.setProperty('--fontsize-navigation-passed', getValueByDefault(style.fontsizeNavigationPassed, styleDefault.fontsizeNavigationPassed))
    styleColor.setProperty('--fontcolor-navigation-passed', getValueByDefault(style.fontColorNavigation, styleDefault.fontColorNavigation))
    styleColor.setProperty('--fontcolor-inactive-navigation-passed', getValueByDefault(style.fontColorInactivoNavigation, styleDefault.fontColorInactivoNavigation))

    styleColor.setProperty('--pregunta-opcion-fondo', getValueByDefault(style.colorPreguntaBotonFondo, styleDefault.colorPreguntaBotonFondo))
    styleColor.setProperty('--pregunta-opcion-texto', getValueByDefault(style.colorPreguntaBotonTexto, styleDefault.colorPreguntaBotonTexto))

    styleColor.setProperty('--color-circulo-animacion-fin', getValueByDefault(style.colorCirculoAnimacionFin, styleDefault.colorCirculoAnimacionFin))
    styleColor.setProperty('--color-check-animacion-fin', getValueByDefault(style.colorCheckAnimacionFin, styleDefault.colorCheckAnimacionFin))
    styleColor.setProperty('--color-texto-animacion-fin', getValueByDefault(style.colorTextoAnimacionFin, styleDefault.colorTextoAnimacionFin))
    styleColor.setProperty('--color-transiciones', getValueByDefault(style.colorTransiciones, styleDefault.colorTransiciones))
    styleColor.setProperty('--primary', getValueByDefault(style.colorTransiciones, styleDefault.colorTransiciones))

    styleColor.setProperty('--image-header-center', getValueByDefault(style.imageAlignHeader, styleDefault.imageAlignHeader))

    styleColor.setProperty('--text-title-page', style.colorTitlePage)
    styleColor.setProperty('--text-subtitle-page', style.colorSubTitlePage)

}
export const getValueByDefault = (value: string, defaultval: string) => {
    return value ? value : defaultval
}
export const SetStyleKeyValue = (key: string, value: string) => {
    styleColor.setProperty(key, value)
}

export const SetTabNavigationStyle = (value: string) => {
    removeClassName("styleNav1")
    removeClassName("styleNav2")
    removeClassName("styleNav3")
    removeClassName("styleNav4")

    if (document.getElementsByClassName('sd-progress-buttons__list-container').length > 0)
        document.getElementsByClassName('sd-progress-buttons__list-container')[0].classList.add(value)
}
const removeClassName = (style: string) => {
    if (document.getElementsByClassName('sd-progress-buttons__list-container').length > 0)
        document.getElementsByClassName('sd-progress-buttons__list-container')[0].classList.remove(style)
}

export const renderQuestionText = (question: any) => {
    let middle = question.contentPanel.getQuestionByName("shippingSameAsBusiness")
    if (!!middle && question.showMiddleName) {
        middle.choices = [question.showMiddleName]
    }
    if (!!middle && question.closeText) {
        let titleModal = document.getElementById('closeButtonLegal')
        if (titleModal)
            titleModal.innerHTML = question.closeText
    }
    if (!!middle && question.closeText) {
        let titleModal = document.getElementById('closeButtonLegal')
        if (titleModal)
            titleModal.innerHTML = question.closeText
    }
}