import { AccountInfo } from "@azure/msal-common/dist/account/AccountInfo.js";
import { getIn } from "formik"
import { Guid } from "guid-typescript";
import { msalInstance } from '../../src/index';
import { DEFAULT_GUID } from "../constants/default";
import { ListaSeleccion } from "../interfaces/listaSeleccion";
import { ROLE } from "../interfaces/roles";
import { Ticket } from "../interfaces/ticketExp";

export const getStylesError = (errors: any, fieldName: string) => {
    if (getIn(errors, fieldName)) {
        return {
            border: '1px solid red'
        }
    }
}

export const fieldRequired = (value: any) => {
    let error;
    if (!value) {
        error = 'Campo requerido'
    }
    return error;
}

export const boolenaToText = (value: boolean) => {
    return value ? 'Si'
        : 'No'
}

/**
 * Si el objeto está vacio retorna TRUE
 * @param {*} obj
 * @returns boolean
 */
export const esObjetoVacio = (obj: object) => {
    return (Object.keys(obj).length === 0)
}

export const replaceObjectInString = (value: string, obj: any) => {
    return value.replace(/:[a-zA-Z?]+/g, function (match) {
        const key = match.slice(1)
        return obj[key]
    })
}

export const replaceObjectInStringTemplate = (value: string, obj: any) => {
    return value.replace(/{[a-zA-Z?]+}/g, function (match) {
        const key = match.slice(1, -1)
        return obj[key]
    })
}

export const replaceObjectInStringTemplateChatGpt = (value: string, obj: any) => {
    return value.replace(/:[a-zA-Z?]+:/g, function (match) {
        const key = match.slice(1, -1)
        return obj[key]
    }) 
}

export const hasPermissions = (roles: Array<ROLE>) => {
    const account = msalInstance.getActiveAccount() as AccountInfo;
    // @ts-ignore    
    let enableRoles = roles.filter(role => account.idTokenClaims?.roles?.includes(role))
    return enableRoles.length > 0
}

export const obtenerValorNull = (objItem: any) => {
    return objItem[0] ?? null

}

export const hablitarServicio = (categoria: Guid, listaSeleccionCategoria: ListaSeleccion[]) => {
    let emableSer: boolean = false
    if (categoria != Guid.parse(DEFAULT_GUID)) {
        let categoriaSelect = listaSeleccionCategoria.filter((x) => x.ListaSeleccionId === categoria);
        if (categoriaSelect.length>0 && categoriaSelect[0].Nombre === 'Alimentos') {
            emableSer = true
        }
    }
    return emableSer
}

export const servicioRequerido = (values: Ticket) => {
    return values.Categoria && values.Categoria.Nombre === 'Alimentos' ? true : false
}

export const nullToString = (_value: any) => {
    try {
        return _value && _value !== "'undefined'" && _value !== null ? _value : ""
    } catch (_ex) {
        return ""
    }
}

export const NumberRound2Decimal = (num: number) => {
    return Math.round(num * 100) / 100
}
