import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { EstiloTexto } from "../../../interfaces/estilo";

const HerramientasPantallaFin = (props: any) => {
    const [loadInfo, setLoadInfo] = useState<boolean>(true);
    let [itemStyle, setItemStyle] = useState<EstiloTexto>({} as EstiloTexto);
    const [backgroudColorFin, setBackgroudColorFin] = useState<string>("");
    const [colorFinInvitado, setColorFinInvitado] = useState<string>("");
    const [colorFinLogeado, setColorFinLogeado] = useState<string>("");
    const [textAlignFinInvitado, setTextAlignFinInvitadoInvitado] = useState<string>("");
    const [textDecorationFinInvitado, setTextDecorationFinInvitado] = useState<string>("");
    const [textWeightFinInvitado, setTextWeightFinInvitado] = useState<string>("");
    const [textAlignFinLogeado, setTextAlignFinLogeado] = useState<string>("");
    const [textDecorationFinLogeado, setTextDecorationFinLogeado] = useState<string>("");
    const [textWeightFinLogeado, setTextWeightFinLogeado] = useState<string>("");


    const defaultTypeStyle = "General";
    const typeStyleList = [
        "General"
    ];
    const [typeStyle, setTypeStyle] = React.useState<string>(defaultTypeStyle)
    const handleChangeTypeStyle = (event: DropDownListChangeEvent) => {
        if (event) {
            setTypeStyle(event.value)
        }
    }

    useEffect(() => {
        if (!loadInfo) return

        setLoadInfo(false)

        let styleTmp: EstiloTexto = (JSON.parse(props.estilo[0]["Cuerpo"]) as EstiloTexto)
        itemStyle = styleTmp
        setItemStyle(styleTmp)

        setStyle("BIN", styleTmp.backgroudColorFin !== undefined ? styleTmp.backgroudColorFin : "#ffffff")
        setStyle("CTI", styleTmp.colorFinInvitado)
        setStyle("CTL", styleTmp.colorFinLogeado)
        setStyle("TAI", styleTmp.textAlignFinInvitado)
        setStyle("TAL", styleTmp.textAlignFinLogeado)
        setStyle("TDI", styleTmp.textDecorationFinInvitado)
        setStyle("TDL", styleTmp.textDecorationFinLogeado)
        setStyle("TWI", styleTmp.textWeightFinInvitado)
        setStyle("TWL", styleTmp.textWeightFinLogeado)

    })
    const setStyle = (tipo: string, style: string) => {
        switch (tipo) {
            case "BIN":
                itemStyle.backgroudColorFin = style
                setBackgroudColorFin(style)
                break
            case "CTI":
                itemStyle.colorFinInvitado = style
                setColorFinInvitado(style)
                break
            case "CTL":
                itemStyle.colorFinLogeado = style
                setColorFinLogeado(style)
                break;
            case "TAI":
                itemStyle.textAlignFinInvitado = style
                setTextAlignFinInvitadoInvitado(style)
                break
            case "TAL":
                itemStyle.textAlignFinLogeado = style
                setTextAlignFinLogeado(style)
                break;
            case "TDI":
                if (itemStyle.textDecorationFinInvitado === style)
                    style = "none"
                itemStyle.textDecorationFinInvitado = style
                setTextDecorationFinInvitado(itemStyle.textDecorationFinInvitado)
                break;
            case "TDL":
                if (itemStyle.textDecorationFinLogeado === style)
                    style = "none"
                itemStyle.textDecorationFinLogeado = style
                setTextDecorationFinLogeado(itemStyle.textDecorationFinLogeado)
                break;
            case "TWI":
                if (itemStyle.textWeightFinInvitado === style)
                    style = "none"
                itemStyle.textWeightFinInvitado = style
                setTextWeightFinInvitado(itemStyle.textWeightFinInvitado)
                break;
            case "TWL":
                if (itemStyle.textWeightFinLogeado === style)
                    style = "none"
                itemStyle.textWeightFinLogeado = style
                setTextWeightFinLogeado(itemStyle.textWeightFinLogeado)
                break;
        }
        props.setStyleProperty("F" + tipo, style)
    }

    return (
        <div className="herramientasTema herramientas-tema-three">
            <div className="scrollHerramientas">
                <DropDownList
                    className="selector"
                    data={typeStyleList}
                    defaultValue={typeStyle}
                    onChange={handleChangeTypeStyle}
                />
                <div className="scrollHerramientas">
                    {typeStyle === "General" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="color-header-three" className="activator"/>
                                <h1><label htmlFor="color-header-three">General</label></h1>
                                <div className="p">
                                    <p className="titleSection">Color de fondo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker" value={backgroudColorFin}
                                                   onChange={(e: any) => setStyle("BIN", e.target.value)}/>
                                            {backgroudColorFin}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="color-log-three" className="activator"/>
                                <h1><label htmlFor="color-log-three">Texto</label></h1>
                                <div className="p">
                                    <p className="titleSection">Color de texto:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker"
                                                   value={colorFinInvitado}
                                                   onChange={(e: any) => setStyle("CTI", e.target.value)}/>
                                            {colorFinInvitado}
                                        </span>
                                    </p>
                                    <p className="titleSection">Herramientas para orientación:</p>
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={textAlignFinInvitado === 'left' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAI", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={textAlignFinInvitado === 'center' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAI", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={textAlignFinInvitado === 'right' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAI", 'right')}/>
                                    </p>
                                    <p>
                                        <Button iconClass="k-icon k-i-bold"
                                                className={textWeightFinInvitado === 'bold' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TWI", 'bold')}/>
                                        <Button iconClass="k-icon k-i-underline"
                                                className={textDecorationFinInvitado === 'underline' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TDI", 'underline')}/>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}

                    {typeStyle === "Explorer" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="color-inv-three" className="activator"/>
                                <h1><label htmlFor="color-inv-three">Explorer</label></h1>
                                <div className="p">
                                    <p className="titleSection">Color de texto:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker"
                                                   value={colorFinLogeado}
                                                   onChange={(e: any) => setStyle("CTL", e.target.value)}/>
                                            {colorFinLogeado}
                                        </span>
                                    </p>
                                    <p className="titleSection">Herramientas para orientación:</p>
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={textAlignFinLogeado === 'left' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAL", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={textAlignFinLogeado === 'center' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAL", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={textAlignFinLogeado === 'right' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAL", 'right')}/>
                                    </p>
                                    <p>
                                        <Button iconClass="k-icon k-i-bold"
                                                className={textWeightFinLogeado === 'bold' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TWL", 'bold')}/>
                                        <Button iconClass="k-icon k-i-underline"
                                                className={textDecorationFinLogeado === 'underline' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TDL", 'underline')}/>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
export default HerramientasPantallaFin
