import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import insertTextAtCursor from "insert-text-at-cursor";
import React from 'react';
import { ListaSeleccion } from '../../../interfaces/listaSeleccion';

interface MessageProps {
    language: ListaSeleccion,
    onShow?: boolean,
    initial: string,
    values: object,
    inputName: string,
    tags?: object[],
}

const MessageComponent = (props: MessageProps) => {
    const {language, onShow, initial, values, inputName, tags} = props
    const [show, setShow] = React.useState<boolean>(onShow || false)
    const [value, setValue] = React.useState<string>(values[language.Valor] || initial)
    const inputBaseName = `${inputName}-${language.Valor}`

    const handleChange = (event: TextAreaChangeEvent) => {
        setValue(event.value)
        values[language.Valor] = event.value
    }

    const handleAddTag = (_inputName: string, replace: string) => {
        let elementInput = document.getElementById(_inputName) as HTMLInputElement
        let elementName = `#${replace}#`
        insertTextAtCursor(elementInput, elementName);
    }

    return (
        <>
            <div className="ruleTab">
                <div className="ruleHeader" onClick={() => setShow(!show)}>
                    <div>{language.Nombre}</div>
                </div>
                <div className="ruleContentView" style={{display: show ? 'block' : 'none'}}>
                    {tags && (
                        <div className="tagSelector">
                            <ul>
                                {tags.map((tag: any) => (
                                    <li key={tag.title} onClick={() => handleAddTag(inputBaseName, tag.replace)}>
                                        <div>{tag.title}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className="__editor three-theme-backgroud">
                        <TextArea
                            rows={2}
                            style={{width: '100%'}}
                            className={["form-control from-control-sm textarea-theme one-text-log",inputName,"one-theme-backgroud"].join(' ')}
                            maxLength={2000}
                            id={inputBaseName}
                            name={inputBaseName}
                            value={value}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}


export default MessageComponent
