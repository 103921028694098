import {
    Input,
    InputChangeEvent,
    NumericTextBox,
    NumericTextBoxChangeEvent
} from '@progress/kendo-react-inputs';
import React from 'react';
import { Loading } from "../../../common/Loading";
import { notifications } from "../../../helpers/notifications";
import { NuevoItemFin } from "../../../interfaces/estilo";
import MessageComponent from '../../Formularios/components/MessageComponent';

const FinalItemEdit = (props: any) => {
    const [loadingState] = React.useState<boolean>(false);
    const [item, setItem] = React.useState<NuevoItemFin>(props.item as NuevoItemFin)
    const [nombreItem, setNombreItem] = React.useState<string>(item.Nombre)
    const [textItem] = React.useState<string>(item.Texto)
    const [rangoInicio, setRangoInicio] = React.useState<number>(item.RangoInicio)
    const [rangoFin, setRangoFin] = React.useState<number>(item.RangoFin)
    const [values] = React.useState<any>(item.Textos || {})

    const validarGuardar = () => {
        return item.Nombre === ""
    }

    const guardar = () => {
        if (!validarGuardar()) {
            item.Nombre = nombreItem
            item.Texto = textItem || ''
            item.RangoInicio = rangoInicio
            item.RangoFin = rangoFin
            item.Textos = values
            let itemTmp = item
            props.addNuevoItem(itemTmp)
        } else {
            notifications({
                title: 'Error reglas',
                message: 'Hay información requerida sin ingresar',
                toastType: 'error'
            });
        }
    }

    const onChangeInputRange = (event: NumericTextBoxChangeEvent) => {
        switch (event.target.name) {
            case "rangoInicio":
                setRangoInicio(event.value !== null ? event.value : 0)
                item.RangoInicio = event.value !== null ? event.value : 0
                break
            case "rangoFin":
                setRangoFin(event.value !== null ? event.value : 0)
                item.RangoFin = event.value !== null ? event.value : 0
                break

        }
        let itemTmp = item
        setItem(itemTmp)
    }

    const onChangeInput = (event: InputChangeEvent) => {
        setNombreItem(event.value)
        item.Nombre = event.value
        let itemTmp = item
        setItem(itemTmp)
    }
    return (
        <>
            {loadingState ? <Loading/> :
                <div className="contentBox positionrelative">
                    <div className="popUpBg">
                        <div className="popUpContainer reglascont">
                            <div className="labelRule">Nuevo ítem</div>
                            <div className="popContainer">
                                <div className="ruleTxt">Nombre del ítem:</div>
                                <div>
                                    <Input type="text" name="nombre" className="inputBox regla-nombre"
                                           placeholder="Nombre" value={nombreItem} onChange={onChangeInput}/>
                                </div>
                                <div className="ruleTxt">Rango inicio:</div>
                                <div>
                                    <NumericTextBox
                                        name="rangoInicio" className="inputBox regla-nombre"
                                        placeholder="Rango inicio" value={rangoInicio}
                                        onChange={onChangeInputRange}
                                    />
                                </div>
                                <div className="ruleTxt">Rango fin:</div>
                                <div>
                                    <NumericTextBox
                                        name="rangoFin" className="inputBox regla-nombre"
                                        placeholder="Rango fin" value={rangoFin}
                                        onChange={onChangeInputRange}
                                    />
                                </div>
                                <div className="ruleTxt">Texto:</div>
                                {props.onLanguage.map((language: any, index: number) => (
                                    <MessageComponent
                                        key={`${language.Valor.toString()}-item-${item.Id}`}
                                        language={language}
                                        onShow={index === 0}
                                        initial={""}
                                        values={values}
                                        inputName={"inicio-logeado"}
                                    />
                                ))}
                                <div className="btnPopup">
                                    <ul>
                                        <li>
                                            <div className="btnCancel" onClick={props.onHandleCloseAddUpdate}>Cerrar
                                            </div>
                                        </li>
                                        <li>
                                            <div className="btnAdd" onClick={() => {
                                                guardar()
                                            }}>Guardar
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            }
        </>
    )
}
export default FinalItemEdit;
