import {MsalProvider} from "@azure/msal-react";
import {IntlProvider, load, loadMessages, LocalizationProvider} from "@progress/kendo-react-intl";
import '@progress/kendo-theme-default/dist/all.css';
import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import ecCaGregorian from "cldr-dates-full/main/es-EC/ca-gregorian.json";
import ecDateFields from "cldr-dates-full/main/es-EC/dateFields.json";
import ecLocalCurrency from "cldr-numbers-full/main/es-EC/currencies.json";
import ecNumbers from "cldr-numbers-full/main/es-EC/numbers.json";
import {
    autocomplete,
    bootstrapslider,
    ckeditor,
    inputmask,
    jquerybarrating,
    nouislider,
    prettycheckbox,
    select2,
    select2tagbox,
    sortablejs,
    speedtotext
} from "custom-metrosurvey-widgets";
import $ from "jquery";
import "jquery-bar-rating";
import React, {lazy, Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import "select2/dist/js/select2.js"; 

// Survey Js 
import * as Survey from "survey-core";
import './assets/css/Smartsurvey.css';
import './assets/css/theme-survey.css';
import "./assets/scss/theme.scss";
import {Loading} from "./common/Loading";
import {ROLE} from "./interfaces/roles";
import {Layout} from './layout/Layout';
import {LayoutEncuesta} from './layout/LayoutEncuesta';
import {LayoutFormulario} from './layout/LayoutFormulario';
import {Login} from "./pages/Authentication/Login";
import {ThemeEstilo} from "./pages/Estilo/components/ThemeEstilo";
import PrivateRoute from "./PrivateRoute";
import ShortTokenRedirect from "./ShortToken";
import esMessages from "./translations/es/global.json";
 
window["$"] = window["jQuery"] = $; 
prettycheckbox(Survey);
select2(Survey, $);
inputmask(Survey);
jquerybarrating(Survey, $);
nouislider(Survey);
select2tagbox(Survey, $);
sortablejs(Survey);
ckeditor(Survey);
autocomplete(Survey, $);
bootstrapslider(Survey);
speedtotext(Survey, $);

loadMessages(esMessages, "es-EC");
load(
    likelySubtags,
    currencyData,
    weekData,
    ecNumbers,
    ecLocalCurrency,
    ecCaGregorian,
    ecDateFields
);

const Home = lazy(() => import('./pages/Home/Home'));
const Prohibido = lazy(() => import("./pages/Prohibido"));
const ListaSeleccion = lazy(() => import( "./pages/ListaSeleccion/ListaSeleccion"));
const Formularios = lazy(() => import("./pages/Formularios"));
const DetalleFormulario = lazy(() => import("./pages/DetalleFormulario/DetalleFormulario"));
const NuevoFormulario = lazy(() => import("./pages/Formularios/NuevoFormulario"));
const UsuarioLocalodad = lazy(() => import("./pages/UsuarioLocalidad/UsuarioLocalodad"));
const ClientForm = lazy(() => import("./pages/Formularios/components/ClientForm"));
const Producto = lazy(() => import("./pages/Producto/Producto"));
const Estilo = lazy(() => import("./pages/Estilo/Estilo"));
const GestionFeedback = lazy(() => import("./pages/GestionFeedback"));
const PreviewEncuesta = lazy(() => import("./pages/GestionFeedback/components/PreviewEncuesta"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const CanalEnvio = lazy(() => import("./pages/CanalEnvio"));
const CanalEnvioTemplate = lazy(() => import("./pages/CanalEnvioTemplate"));
const CanalEnvioParameter = lazy(() => import("./pages/CanalEnvioParametrizacion"));
const TextAnalysis = lazy(() => import("./pages/TextAnalysis"));
const TextAnalysisConfig = lazy(() => import("./pages/TextAnalysisConfiguracion"));
const AnalisisSentimientos = lazy(() => import("./pages/AnalisisSentimientos/AnalisisSentimientos"));


// @ts-ignore
//<Route path="*" element={<NotFound />} />
function App({pca}) {

    return (
        <LocalizationProvider language="es-EC">
            <IntlProvider locale="es-EC">
                <Suspense fallback={
                    <div id="preloader">
                        <Loading/>
                    </div>}>
                    <MsalProvider instance={pca}>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.EditarFormulario, ROLE.VisorFormularioActivo, ROLE.VisorListaSeleccion, ROLE.VisorFormularioEliminado, ROLE.VisorUsuarioLocalidad, ROLE.VisorEncuesta, ROLE.VisorProducto, ROLE.VisorCanalEnvio]}><Layout
                                Component={Home}/></PrivateRoute>}/>
                            <Route path="/lista-seleccion" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.VisorListaSeleccion]}><Layout
                                Component={ListaSeleccion}/></PrivateRoute>}/>
                            <Route path="/usuario-localidad" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.VisorUsuarioLocalidad]}><Layout
                                Component={UsuarioLocalodad}/></PrivateRoute>}/>
                            <Route path="/producto" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.VisorProducto]}><Layout
                                Component={Producto}/></PrivateRoute>}/>
                            <Route path="/formularios/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.EditarFormulario, ROLE.VisorFormularioActivo, ROLE.VisorFormularioEliminado]}><Layout
                                Component={Formularios}/></PrivateRoute>}/>
                            <Route path="/sentimientos/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.GestionFeedbackResponsable]}><Layout
                                Component={AnalisisSentimientos}/></PrivateRoute>}/>

                            <Route path="/detalle-formulario/:formularioId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.EditarFormulario, ROLE.VisorFormularioActivo, ROLE.VisorFormularioEliminado]}><Layout
                                Component={DetalleFormulario}/></PrivateRoute>}/>
                            <Route path="/nuevo-formulario" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.EditarFormulario]}><LayoutFormulario
                                Component={NuevoFormulario}/></PrivateRoute>}/>
                            <Route path="/nuevo-formulario/:formularioId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.EditarFormulario]}><LayoutFormulario
                                Component={NuevoFormulario}/></PrivateRoute>}/>
                            <Route path="/encuesta/:token" element={<LayoutEncuesta Component={ClientForm}/>}/>
                            <Route path="/survey/:token" element={<ShortTokenRedirect />}/>
                            <Route path="/preview/:token" element={<LayoutEncuesta Component={PreviewEncuesta}/>}/>
                            <Route path="/prohibido" element={<LayoutEncuesta Component={Prohibido}/>}/>
                            <Route path="/estilo" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.VisorProducto]}><Layout
                                Component={Estilo}/></PrivateRoute>}/>

                            <Route path="/closethelopp/:action/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.EditarFormulario, ROLE.GestionFeedback, ROLE.GestionFeedbackResponsable]}><Layout
                                Component={GestionFeedback}/></PrivateRoute>}/>

                            <Route path="/estilo/tema/:estiloId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.VisorProducto]}><Layout
                                Component={ThemeEstilo}/></PrivateRoute>}/>

                            <Route path="/dashboard/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.Dashboard, ROLE.DashboardBI]}><Layout
                                Component={Dashboard}/></PrivateRoute>}/>

                            <Route path="/canales-envio/registro/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.CanalEnvio, ROLE.VisorCanalEnvio]}><Layout
                                Component={CanalEnvio}/></PrivateRoute>}/>

                            <Route path="/canales-envio/plantilla/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.CanalEnvio]}><Layout
                                Component={CanalEnvioTemplate}/></PrivateRoute>}/>

                            <Route path="/canales-envio/parametrizacion/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.CanalEnvio]}><Layout
                                Component={CanalEnvioParameter}/></PrivateRoute>}/>

                            <Route path="/dashboardcanales/:localidadId/:canalesenvio" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema, ROLE.Dashboard]}><Layout
                                Component={Dashboard}/></PrivateRoute>}/>

                            <Route path="/analisis-sentimientos/:localidadId" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema]}><Layout
                                Component={TextAnalysis}/></PrivateRoute>}/>
                            
                            <Route path="/configuracion-chat-gpt/" element={<PrivateRoute
                                roles={[ROLE.AdministradorSistema]}><Layout
                                Component={TextAnalysisConfig}/></PrivateRoute>}/>

                        </Routes>
                    </MsalProvider>
                </Suspense>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default App;

