import PropTypes from "prop-types"
import { Col, Container, Row } from "reactstrap"

const Footer = (props: any) => {
    return (
        <>
            <footer className={["footer", props.styleMenu !== undefined ? " footer-survey" : ""].join(' ')}>
                <Container fluid={true}>
                    <Row>
                        <Col md={6}>{new Date().getFullYear()} © Metropolitan Touring</Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

Footer.propTypes = {
    leftMenu: PropTypes.any,
    styleMenu: PropTypes.any
};

const mapStatetoProps = (state: any) => {
    const {leftMenu} = state.Layout;
    return {leftMenu};
};

export default Footer;
