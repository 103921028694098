import React from 'react';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js'
import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {createBrowserHistory} from 'history'

const browserHistory = createBrowserHistory({basename: ''})
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory},
        },
    },
})
appInsights.loadAppInsights()
export {reactPlugin, appInsights}
