import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import '../../assets/css/Login.css';
import '../../assets/css/reset.css';
import { loginRequest } from "../../authConfig";

export const Login = () => {
    const navigate = useNavigate();
    const {instance, accounts} = useMsal();
    let username = ''
    if (accounts.length > 0) {
        username = accounts[0].username;
    }

    const handleLogin = () => {
        instance.loginPopup(loginRequest).then((res: any) => {
            console.log(res)
            if (res.idTokenClaims["roles"] !== undefined && res.idTokenClaims["roles"] !== null && res.idTokenClaims["roles"].length > 0) {
                navigate("/"); // if you want to direct the user to a page after login
            } else {
                navigate("/prohibido");
            }
        }).catch(e => {
            console.log(e);
        });
    };


    return (
        <>
            <div className="main loginPage">
                <div className="container">
                    <div className="login">
                        <div className="right-content">
                        </div>
                        <div className="left-content">
                            <h1>{username !== "" ? "¡Bienvenido de nuevo!" : "¡Bienvenido!"}</h1>
                            {username !== "" ? <div className="emailtxt">
                                <AuthenticatedTemplate>
                                    {username}
                                </AuthenticatedTemplate>
                            </div> : ""
                            }
                            <div className="txtcontent">Inicie sesión para continuar con<br/>Explorer SmartSurvey</div>
                            <div className="btn-login" onClick={() => handleLogin()}>Iniciar sesión con Microsoft</div>
                        </div>
                    </div>
                    <div className="copyright">© 2023 Metropolitan Touring</div>
                </div>
            </div>
        </>
    )
}
