// users
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import authHeader from "../../../helpers/authHeader";
import { msalInstance } from "../../../index";
import { sinFoto } from "../../../constants/default";
import {ResponseType,Client}  from "@microsoft/microsoft-graph-client"


const ProfileMenu = () => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false)
    const [foto, setFoto] = useState<any>(sinFoto);
    const {instance} = useMsal();

    const account = msalInstance.getActiveAccount()?.name;

    const handleLogout = () => {
        localStorage.removeItem("usr_localidad")
        localStorage.removeItem("usr_tipoEnvio")
        localStorage.removeItem("usr_estado")
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });

        sessionStorage.clear();

    }
    const getFoto = useCallback(async () => {
        const accessToken = (await authHeader()).accessRokenGraph;
        const client = Client.init({
            authProvider: (done) => {
                done(null, accessToken)
            }
        });
        const result = await client.api('me/photos/48x48/$value')
            .responseType(ResponseType.BLOB)
            .get();
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
            const base = reader.result;
            setFoto(base);
        }
    }, []);

    useEffect(() => {
        getFoto();
    }, [getFoto])


    return (
        <>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item "
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={foto}
                        alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{account}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">


                    <div className="dropdown-divider"/>
                    <DropdownItem tag="a" className="dropdown-item" onClick={() => handleLogout()}>
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
                        <span>Salir</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    )
}

export default ProfileMenu

