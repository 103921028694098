import { SetStyleKeyValue } from "../components/Common/Survey/Utils";
import { apiFormularioEnvio } from '../constants/api';
import { DEFAULT_LANG } from "../constants/default";
import { get } from '../helpers/api_helper';
import { replaceObjectInString } from '../helpers/utils';
import { EstiloTexto, NuevoItemFin, PreguntaSeleccionada } from "../interfaces/estilo";
import { ColumnaFormulario } from "../interfaces/formularios";

const base64 = require('base-64');

export const defaultStyle: any =
    {
        "colorHeader": "#ffffff",
        "colorFondo": "#ffffff",
        "colorCelda": "#0096fc",
        "colorTextoContenido": "#000000",
        "fontFamily": "Arial",
        "bordeCelda": "#ffffff",
        "fontSize": "14px",
        "fondoBtn": "#20c6c6",
        "coloreTextBtn": "#ffffff",
        "fondoBtnHover": "#168b8b",
        "fondoQuestion": "#ffffff",
        "colorTitleHeader": "#050505",
        "colorSubtitleHeader": "#000000",
        "textAlignQuestion": "left",
        "textWeightQuestion": "none",
        "textDecorationQuestion": "none",
        "textAlignTitleHeader": "center",
        "textWeightTitleHeader": "none",
        "textDecorationTitleHeader": "none",
        "textAlignSubTitleHeader": "center",
        "textWeightSubTitleHeader": "none",
        "textDecorationSubTitleHeader": "none",
        "colorInicioLogeado": "#000000",
        "textAlignInicioLogeado": "center",
        "textDecorationInicioLogeado": "",
        "textWeightInicioLogeado": "",
        "backgroudColorInicio": "#ffffff",
        "backgroudColorFin": "#ffffff",
        "textDecorationFinInvitado": "none",
        "textWeightFinInvitado": "none",
        "itemsFinEncuesta": [],
        "textoInicioLogeado": "Estimad@ #CLIENTE# esperamos que tu experiencia en #PRODUCTO# haya sido memorable, por favor cuéntenos más detalles para brindarle siempre un servicio de la más alta calidad. ",
        "textoInicioInvitado": "Hola esperamos que tu experiencia en #PRODUCTO# haya sido maravillosa, por favor cuéntanos más detalles para brindarte siempre un servicio de la más alta calidad. ",
        "colorFinInvitado": "#000000",
        "preguntaSeleccionada": "{\"title\":\"¿Qué tan probable es que recomiende Mashpi  a sus familiares y amigos?\",\"field\":\"¿Qué tan probable es que recomiende Mashpi  a sus familiares y amigos?\",\"tipo\":\"barrating\",\"show\":true}",
        "tabStyle": "styleNav3",
        "textDecorationInicioInvitado": "",
        "textWeightInicioInvitado": "",
        "textDecorationFinLogeado": "none",
        "textWeightFinLogeado": "none",
        "backgroundNavigation": "#e5e5e5",
        "backgroundNavigationCurrent": "#ddc48d",
        "backgroundNavigationPassed": "#caa657"
    }
export const optionsFontSize =
    [
        {"text": "8px"},
        {"text": "9px"},
        {"text": "10px"},
        {"text": "11px"},
        {"text": "12px"},
        {"text": "13px"},
        {"text": "14px"},
        {"text": "15px"},
        {"text": "16px"},
        {"text": "17px"},
        {"text": "18px"},
        {"text": "19px"},
        {"text": "20px"},
        {"text": "21px"},
        {"text": "22px"}
    ]

export const setPreguntaRespuesta = (detRespuesta: any, col: ColumnaFormulario, item: any) => {
    if (col.fieldName && detRespuesta[col.fieldName.toString()] !== undefined) {

        switch (col.tipo) {
            case "fullname":
                item[col.field.toString()] = detRespuesta[col.fieldName.toString()]["firstName"] + "," + detRespuesta[col.fieldName.toString()]["lastName"]
                break;
            case "datos":
                item[col.field.toString()] = detRespuesta[col.fieldName.toString()] + "_"
                break
            case "termsconditions":
                if (detRespuesta[col.fieldName.toString()]["shippingSameAsBusiness"] !== undefined)
                    item[col.field.toString()] = detRespuesta[col.fieldName.toString()]["shippingSameAsBusiness"][0]
                break
            case "matrix":
                let respuesta = "";
                if (detRespuesta[col.fieldName.toString()] !== undefined) {
                    for (let key in detRespuesta[col.fieldName.toString()]) {
                        if (respuesta !== "")
                            respuesta = respuesta + ", "
                        respuesta = respuesta + key + "=" + detRespuesta[col.fieldName.toString()][key]
                    }
                }
                item[col.field.toString()] = respuesta
                break
            default:
                item[col.field.toString()] = detRespuesta[col.fieldName.toString()]
                break;
        }
    } else
        item[col.field.toString()] = ""
}

export const hideButton = (element: HTMLElement) => {
    if (element) {
        element.style.display = "none";
    }
}

export const updateBotonesPagina = () => {
    setTimeout(() => {
        let surveyInternalPrev = (document.getElementById('survey_page_prev') as HTMLElement)
        let surveyInternalNext = (document.getElementById('survey_page_next') as HTMLElement)
        let surveyPrev = (document.getElementById('sv-nav-prev') as HTMLElement)
        let surveyNext = (document.getElementById('sv-nav-next') as HTMLElement)

        hideButton(surveyInternalPrev)
        hideButton(surveyInternalNext)
        hideButton(surveyNext)
        hideButton(surveyPrev)

        try {
            if (!$('.sd-navigation__start-btn').is(":visible")) {
                if (!$('#sv-nav-complete').is(":visible")) {
                    if (window["btncompleted"] && getQuestionVisible(window["survey"].currentPage.questions) <= (window["count"] + window['survey'].pageNumber))
                        $('#sv-nav-complete').show()
                    else
                        surveyInternalNext.style.display = "block";
                } else {
                    if (getQuestionVisible(window["survey"].currentPage.questions) > (window["count"] + window['survey'].pageNumber)) {
                        surveyInternalNext.style.display = "block";
                        $('#sv-nav-complete').hide()
                        window["btncompleted"] = true
                    }
                }
                if (window["pageAct"] >= 1) {
                    surveyInternalPrev.style.display = "block";
                }
            }
        } catch (ex) {

        }
    }, 100)
}
export const getButtonIniText = (currentJSON: any) => {
    if (currentJSON?.startSurveyText) {
        if (currentJSON.startSurveyText["es"] !== undefined)
            return currentJSON.startSurveyText["es"]
        else
            return currentJSON.startSurveyText
    }
    return "Inicio"
}
export const getButtonText = (type: string,lang:string) => {
    switch (type) {
        case "SIG":
            if (window["formulario"]?.pageNextText) {
                if (window["formulario"].pageNextText[lang] !== undefined)
                    return window["formulario"].pageNextText[lang]
                else
                    if(window["formulario"].pageNextText['default']!== undefined)
                        return window["formulario"].pageNextText['default']
                    else
                        return window["formulario"].pageNextText
            }
            return "Siguiente"
        case "ANT":
            if (window["formulario"]?.pagePrevText) {
                if (window["formulario"].pagePrevText[lang] !== undefined)
                    return window["formulario"].pagePrevText[lang]
                else
                    if(window["formulario"].pagePrevText['default']!== undefined)
                        return window["formulario"].pagePrevText['default']
                    else
                        return window["formulario"].pagePrevText
            }

            return "Anterior"
    }
}
export const setCurrentPageSurvey = (currentPage:number) => {
    let items:any = document.getElementsByClassName("sd-progress-buttons__list")
    if(items!==undefined && items.length>0){
        try{
            let isCurrent = 1
            for (const element of items[0].children) {                            
                if(isCurrent==currentPage){
                    element.classList.add("sd-progress-buttons__list-element--current")                    
                }else{
                    element.classList.remove("sd-progress-buttons__list-element--current")
                }
                isCurrent++;
            }
        }catch(_error){
        }
    }
}
export const addEventPag = (lang:string) => {
    let surveyInternalPrev = (document.getElementById('survey_page_prev') as HTMLElement)
    window["pageAct"] = 0

    let textAnterior = getButtonText("ANT",lang)
    let textSiguiente = getButtonText("SIG",lang)

    window["survey"]["locale"] = lang
    window["nav"] = "next"
    window["count"] = window['survey'].pageNumber - 1    
    window['survey'].addNavigationItem({
        id: "survey_page_prev",
        title: textAnterior,
        visibleIndex: 44,
        action: () => {
            window["nav"] = "prev"
            let numberPag = window['survey'].pageNumber;

            if (window["count"] >= window['survey'].pageNumber && window["count"] < getQuestionVisible(window["survey"].currentPage.questions) + (numberPag - 1)) {
                let nextNav = window["count"] - window['survey'].pageNumber,
                    prevNav = window["count"] - (window['survey'].pageNumber * 2)
                handleChangePage(prevNav, nextNav)
                window["count"] = nextNav
                window["pageAct"] = window["pageAct"] - 1
            } else {
                window["survey"].prevPage()
                window["pageAct"] = window["pageAct"] - 1
            }
            if (window["count"] < window['survey'].pageNumber && window['survey'].isFirstPage) {
                if (surveyInternalPrev === undefined || surveyInternalPrev === null)
                    surveyInternalPrev = (document.getElementById('survey_page_prev') as HTMLElement)
                surveyInternalPrev.style.display = "none";
            }
            updateBotonesPagina()
        }
    })
    window["survey"].onAfterRenderPage.add((sender:any, options:any) => {
        let items:any = document.getElementsByClassName("sd-progress-buttons__list")
        if(items!==undefined && items.length>0){
            try{
                let isCurrent = false
                for (const element of items[0].children) {            
                    if(isCurrent){
                        element.classList.remove("sd-progress-buttons__list-element--passed")
                    }
                    if(element.className.indexOf("sd-progress-buttons__list-element--current")>=0){
                        isCurrent = true
                    }
                }
            }catch(_error){
            }
        }
    });
    window["survey"].addNavigationItem({
        id: "survey_page_next",
        title: textSiguiente,
        visibleIndex: 49,
        action: () => {
            window["nav"] = "next"

            if(window['survey'].pageNumber===undefined){
                loadPageNumber()
                window["count"] = window['survey'].pageNumber - 1
            }                


            let numberPag = window['survey'].pageNumber;

            if (validarRequerido()) {
                window["survey"].currentPage.hasErrors(true)
                return
            } else {
                window["survey"].currentPage.hasErrors(false)
            }

            if (window["count"] < getQuestionVisible(window["survey"].currentPage.questions) - 1) {
                if (surveyInternalPrev === undefined || surveyInternalPrev === null)
                    surveyInternalPrev = (document.getElementById('survey_page_prev') as HTMLElement)

                surveyInternalPrev.style.display = 'inline';
                let prevNav = window["count"], nextNav = window["count"] + numberPag
                window["count"] = nextNav
                window["pageAct"] = window["pageAct"] + 1
                handleChangePage(prevNav, nextNav)
            } else {
                window["survey"].nextPage()
                window["pageAct"] = window["pageAct"] + 1
            }
            updateBotonesPagina()
        }
    })
    window["survey"].onVisibleChanged.add((_survey: any) => {
        setTimeout(updateLastPage, 100)
    });

    updateBotonesPagina()
}
export const validarRequerido = () => {
    let index = window["count"]
    let initIndex = window["count"] - (window['survey'].pageNumber - 1)
    while (initIndex <= index) {
        try {
            let itemQuestion = window["survey"].currentPage.questions[initIndex]
            if ((itemQuestion.isVisible || itemQuestion.propertyHash.isVisible) && (itemQuestion.isRequired || itemQuestion.propertyHash.isRequired) && (!itemQuestion.isAnswered || !itemQuestion.propertyHash.isAnswered)) {
                return true
            }
        } catch (_ex) {

        }
        initIndex++
    }
    return false
}
export const updateLastPage = () => {
    if(window['survey'].pageNumber===undefined){
        loadPageNumber()
        window["count"] = window['survey'].pageNumber - 1
    } 
    handleChangePage(window["count"] - window['survey'].pageNumber, window["count"])
}
export const getQuestionVisible = (arrQuery: any) => {
    return arrQuery.filter(function (item: any) {
        return item.isVisible || item.propertyHash.isVisible;
    }).length
}
export const handleChangePage = (prevNav: number, nextNav: number) => {
    const questions = (document.getElementsByClassName("sd-page")[0] as HTMLElement).children
    let indexSkeep = 0
    Array.from(questions).forEach((question, index) => {
        let tmp = question as HTMLElement;
        if (!tmp.classList.contains("sd-title") && !tmp.classList.contains("sd-description")) {
            tmp.style.setProperty('opacity', '0', 'important')
            tmp.style.setProperty('height', '0', 'important')
            tmp.style.setProperty('margin', '0', 'important')
        } else {
            indexSkeep++
        }
        if (index > (prevNav + indexSkeep) && index <= (nextNav + indexSkeep)) {
            tmp.style.setProperty('height', 'auto', 'important')
            tmp.style.setProperty('opacity', '1', 'important')
            tmp.style.removeProperty('margin')
        }
    })
}
export const updateStyleByChange = () => {
    if (document.getElementsByClassName('svd-simulator-content').length > 0 && document.getElementsByClassName('container-theme').length <= 0) {
        let surveyInternalPrev = (document.getElementsByClassName('svd-simulator-content')[0] as HTMLElement)
        surveyInternalPrev.classList.add("container")
        surveyInternalPrev.classList.add("container-theme")
    }
    updateBotonesPagina()
}

export const setStyleByCondtions = (device: string, landscape: boolean, lastMovilSelected: string, lastOrientationSelected: string) => {
    let stylePreview = (document.getElementsByClassName('headerFixed')[0] as HTMLElement)
    let stylePreviewOri = (document.getElementsByClassName('main-content-survey-form')[0] as HTMLElement)

    stylePreview.classList.remove(lastMovilSelected)
    stylePreview.classList.add(device)
    lastMovilSelected = device

    if (landscape) {
        stylePreviewOri.classList.remove("preview-vertical")
        stylePreviewOri.classList.add("preview-horizontal")
        lastOrientationSelected = "preview-horizontal"
    } else {
        stylePreviewOri.classList.remove("preview-horizontal")
        stylePreviewOri.classList.add("preview-vertical")
        lastOrientationSelected = "preview-vertical"
    }
    return lastMovilSelected + '-' + lastOrientationSelected
}
export const openPreviewByParams = async (dataItem: any) => {
    let params = {
        formId: dataItem.FormularioRespuesta.FormularioId,
        formEnvioId: dataItem.FormularioRespuesta.FormularioEnvioId
    };
    const code = dataItem.CodigoIntegracion ?? ''
    let tipoEnvioId: any;

    const url = replaceObjectInString(apiFormularioEnvio, params);
    const resp = await get(url);

    tipoEnvioId = resp.value[0].TipoEnvioId;
    window.open(
        `/preview/${base64.encode(
            'f=' +
            dataItem.FormularioRespuesta.FormularioId +
            '|c=' +
            code +
            '|t=' +
            tipoEnvioId +
            '|e=' +
            dataItem.FormularioRespuesta.FormularioRespuestaId
        )}`
    ); 
}
export const handleButtonClickActionTicket = (action: string, props: any, dataItem: any, navigate: any) => {
    switch (action) {
        case 'agregarTicketDescargo':
            props.handleOpenAgregarTicketDescargo(dataItem);
            break;
        case 'detail':
            return navigate(`/closethelopp/abiertos/${dataItem.LocalidadId}/${dataItem.TicketId}`);
        case 'edit':
            props.handleOpenEdit(dataItem);
            break;
        case 'preview':
            openPreviewByParams(dataItem).catch(()=>{})
            return;
        case 'mostrarTracking':
            let token2 = base64.encode('e=' + dataItem.TicketId);
            props.handleOpenTracking(token2);
            break;
        default:
            console.log(`Opcion ${action} no configurada`);
            return;
    }
};
export const obtenerEncuestaFinal = (itemStyle: EstiloTexto, encuesta: any, lang:string) => {
    let textoLogeado: string = "Gracias por tu respuesta!<br/> Tu opinión es muy valiosa! Hasta la próxima!"
    try {
        let itemsFin: NuevoItemFin[] = (itemStyle.itemsFinEncuesta ?? [] as NuevoItemFin[])
        let valorRespuesta = 10;
        if (itemStyle.preguntaSeleccionada !== undefined) {
            let preguntaSel: PreguntaSeleccionada = (JSON.parse(itemStyle.preguntaSeleccionada) as PreguntaSeleccionada)
            if (isNaN(parseInt(preguntaSel.field)))
                valorRespuesta = parseInt(encuesta[preguntaSel.field])
            else
                valorRespuesta = parseInt(encuesta[parseInt(preguntaSel.field)])
        }
        itemsFin.forEach((element: NuevoItemFin) => {
            if (element.RangoInicio <= valorRespuesta && element.RangoFin >= valorRespuesta)
            {
                if(element.Textos!==undefined)    
                    textoLogeado = element.Textos[lang] ?? element.Texto
                else
                    textoLogeado = element.Texto                
            }
        });
    } catch (ex) {

    }

    return textoLogeado
}
export const loadPageNumber = () => {
    if (window['formulario'].pages[window["survey"].currentPage.propertyHash["visibleIndex"]].pageNumberPreg !== undefined)
        window['survey'].pageNumber = window['formulario'].pages[window["survey"].currentPage.propertyHash["visibleIndex"]].pageNumberPreg
    else
        window['survey'].pageNumber = 1 
}
export const doOnCurrentPageChanged = (surv: any) => {
    let surveyInternalPrev = (document.getElementById('survey_page_prev') as HTMLElement)
    let surveyInternalNext = (document.getElementById('survey_page_next') as HTMLElement)
    surveyInternalPrev.style.display = "inline";
    surveyInternalNext.style.display = "inline";

    window["btncompleted"] = false
    $('#sv-nav-complete').show()

    loadPageNumber()

    let lenQuestion = getQuestionVisible(surv.currentPage.questions)
    if (window["nav"] === "prev" && lenQuestion > 1) {
        let tmpResiduo = lenQuestion % surv.pageNumber
        window["count"] = lenQuestion - 1

        let prevNav = window["count"] - surv.pageNumber, nextNav = window["count"]
        if (tmpResiduo !== 0) {
            prevNav = window["count"] - tmpResiduo
        }
        setTimeout(handleChangePage, 1, prevNav, nextNav)
        window["count"] = nextNav

        if (tmpResiduo > 0)
            window["count"] = nextNav + (surv.pageNumber - tmpResiduo)

    }
    if (window["nav"] === "next") {
        window["count"] = surv.pageNumber - 1
        setTimeout(handleChangePage, 1, -1, window["count"])
    }
    SetStyleKeyValue("--display-title", "")
}
export const getImageLogo = (encuesta: any) => {
    /*let width = '300px'
    let heigth = '200px'

    if (encuesta['logoWidth'] !== undefined)
        width = encuesta['logoWidth']
    if (encuesta['logoHeight'] !== undefined)
        heigth = encuesta['logoHeight']

    if (encuesta['logo'] !== undefined) {
        if (encuesta['logo'].toString().indexOf("data:image") === 0)
            return `<img class='image-logo-inicio' src="${encuesta['logo']}" width="${width}" height="${heigth}"/>`
        return encuesta['logo']['es'] !== undefined ? `<img class='image-logo-inicio' src="${encuesta['logo']['es']}" width="${width}" height="${heigth}"/>` : `<img src="${encuesta['logo']['default']}" width="${width}" height="${heigth}"/>`
    } else*/
        return ""
}
export const getImageLogoImage = (encuesta: any) => {
    let width = '300px'
    let defaultLan = encuesta["locale"] ?? DEFAULT_LANG
    if (encuesta['logoWidth'] !== undefined) width = encuesta['logoWidth']

    if (encuesta['logo'] !== undefined) {
        if (encuesta['logo'].toString().indexOf("data:image") === 0)
            return <img className='image-logo-inicio' src={encuesta['logo']} width={width} alt={"img"}/>
        return encuesta['logo'][defaultLan] !== undefined ? <img className='image-logo-inicio' src={encuesta['logo'][defaultLan]} width={width} alt={"img"}/> : <img src={encuesta['logo']['default']} width={width} alt={"img"}/>
    } else
        return ""
}

export const normalizeSurveyJSON = (jsonResult: any, estilo: EstiloTexto, producto: string, lang:string) => {
    if (estilo && estilo.textoInicioInvitado !== "") {
        if (estilo.textoInicioInvitado === undefined) {
            estilo.textoInicioInvitado = "";
        }
        let currentJSON = JSON.parse(jsonResult)
        let endPage = "<div class='loading-fin'>" + loadingFin() + "</div><div class='animate scale-up-center hide-item'><div class='animateMask'></div><div class='animateTick'><i class='bx bx-check'></i></div></div><span id='span-fin-text' class='hide-item'></span>"

        let bolShowProgressBar = false

        if (currentJSON["showNavegacion"] === undefined || currentJSON["showNavegacion"] === "Mostrar")
            bolShowProgressBar = true

        if (bolShowProgressBar) {
            currentJSON["showProgressBar"] = "top"
        } else {
            currentJSON["showProgressBar"] = "off"
        }

        currentJSON["logoPosition"] = "right"
        currentJSON["progressBarType"] = "buttons"
        currentJSON["startSurveyText"] = getButtonIniText(currentJSON)
        currentJSON["completedHtml"] = endPage

        return JSON.stringify(currentJSON)
    }
    return jsonResult
}
export const loadingFin = () => {
    return "<div id='status'><div class='spinner-chase'><div class='chase-dot'></div><div class='chase-dot'></div><div class='chase-dot'></div><div class='chase-dot'></div><div class='chase-dot'></div><div class='chase-dot'></div></div></div>"
}
export const setFinText = (finText: string) => {
    if ($("#span-fin-text").length > 0)
        $("#span-fin-text").html(finText)
    else
        setTimeout(setFinText, 1000, finText)
}
export const slugify = (text: any) => {
    return text
        .toString()
        .normalize('NFKD')
        .toLowerCase()
        .trim()
        .replace(/\s/g, '-')
        .replace(/[^\w\-]+/g, '');
}

export const getIdiomasFormulario = (formulario:string) =>{
    let lang:string[] = ["es"]
    let langPermit:string[] = ["es","en","fr","de","pt"]
    if(formulario!==undefined)
    {
        let formularioDet = JSON.parse(formulario)   
        lang = [formularioDet["locale"] ?? DEFAULT_LANG]
        formularioDet["pages"].forEach((element: any) => {
            element["elements"].forEach((preg: any) => {
                if(preg["title"]!==undefined){
                    let keys = Object.keys(preg["title"]);
                    keys.forEach((key: any) => {
                        let langExtiste = lang.find(obj => {
                            return obj === key;
                          })
                        
                        if(langPermit.includes(key) && !langExtiste){
                            lang.push(key)
                        }
                    })
                }
            })
        })
    }
    return lang
}
export const getTextoInicio = (lang:string,guest:boolean,style:any,producto:string) => {
    try{
        console.log("getTextoInicio:" + lang,guest,style)
        if(style!==undefined){
            if(lang==="es")
                if(guest===true)
                    return style["listWelcomeGuest"][lang]!=undefined?style["listWelcomeGuest"][lang].replace("#PRODUCTO#",producto.toUpperCase()):style["textoInicioInvitado"].replace("#PRODUCTO#",producto.toUpperCase())
                else
                    return style["textoInicioLogeado"][lang]!=undefined?style["textoInicioLogeado"][lang].replace("#PRODUCTO#",producto.toUpperCase()):style["textoInicioLogeado"].replace("#PRODUCTO#",producto.toUpperCase())
            else
                return guest?style["listWelcomeGuest"][lang].replace("#PRODUCTO#",producto.toUpperCase()):style["listWelcomeUser"][lang].replace("#PRODUCTO#",producto.toUpperCase())
        }   
    } catch(_error){

    }
}
export const getTextoBotones = (lang:string,label:string,formulario:any) => {
    try{
        if(formulario===undefined) return ""

        let formuliarioJObject = JSON.parse(formulario)
        let localeDef = formuliarioJObject["locale"] ?? DEFAULT_LANG

        if(formuliarioJObject[label][lang]!==undefined) return formuliarioJObject[label][lang]
        if(lang===localeDef && formuliarioJObject[label]["default"]!==undefined) return formuliarioJObject[label]["default"]
        if(formuliarioJObject[label]!==undefined)  return formuliarioJObject[label]
    }catch(ex){

    }
    switch(label){
        case "startSurveyText":
            return "Inicio";
        case "pagePrevText":
            return "Anterior";
        case "pageNextText":
            return "Siguiente";
        case "completeText":
            return "Completar";
        case "previewText":
            return "Vista previa";
        case "editText":
            return "Editar";
    }
    return "Inicio"
}