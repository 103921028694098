import { protectedResources } from "../authConfig";
import { msalInstance } from "../index";

export async function authHeader() {
    const currentAccount = msalInstance.getActiveAccount();

    if (!currentAccount) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        account: currentAccount,
        scopes: protectedResources.apiSmartsurvey.scopes
    });
    const graphResponse = await msalInstance.acquireTokenSilent({
        account: currentAccount,
        scopes: ["User.Read"]
    });
    return {accessToken: response.accessToken,accessRokenGraph:graphResponse.accessToken, currentAccount};
}

export default authHeader;

