import {ComboBox, ComboBoxChangeEvent} from "@progress/kendo-react-dropdowns";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.js"
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "bootstrap/dist/css/bootstrap.css";
import "easy-autocomplete/dist/easy-autocomplete.css";
import "jquery-bar-rating";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "jquery-bar-rating/jquery.barrating.js";
import "jquery-ui-dist/jquery-ui.css";
import "jquery-ui/themes/base/all.css";
import "nouislider/dist/nouislider.min.css";
import "pretty-checkbox/dist/pretty-checkbox.css";
import React, {useEffect, useState} from "react";

import "select2/dist/js/select2.js";
import {StylesManager} from "survey-core";
import "survey-core/defaultV2.css";
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.css";
import "survey-creator-core/survey-creator-core.i18n.js";
import {localization} from "survey-creator-react";


import "survey-react/survey.css";
import '../../../components/Common/Survey/Style.css'
import {SetStyleCustom} from "../../../components/Common/Survey/Utils";
import {apiSelectionListByLocation} from "../../../constants/api";
import {DEFAULT_GUID} from "../../../constants/default";
import {get, post, put} from "../../../helpers/api_helper";
import {getPregunstasFormulario} from "../../../helpers/formulario";
import {notifications} from "../../../helpers/notifications";


import {spanishTranslation} from '../../../helpers/survey/es'
import {EstiloTexto, NuevoItemFin, ValoresPantallaInicioFin} from "../../../interfaces/estilo";
import {ColumnaFormulario} from "../../../interfaces/formularios";
import {ListaSeleccion as IListaSeleccion} from '../../../interfaces/listaSeleccion';
import MessageComponent from '../../Formularios/components/MessageComponent';
import {useThemeGoodBye} from '../hooks/useThemeGoodBye';
import FinalItem from "./FinalItem";
import FinalItemEdit from "./FinalItemEdit";
import HerramientasPantallaFin from "./HerramientasPantallaFin";
import HerramientasPantallaInicio from "./HerramientasPantallaInicio";
import HerramientasThema from "./HerramientasThema";
import { Guid } from "guid-typescript";
import SurveyRender from "./SurveyRender";

require("emotion-ratings/dist/emotion-ratings.js");
require("jquery-ui-dist/jquery-ui.js");
require("easy-autocomplete/dist/jquery.easy-autocomplete.js");
require("velocity-animate/velocity.js");

window["$"] = window["jQuery"] = $;

localization.locales["es"] = spanishTranslation;
localization.currentLocale = "es"
StylesManager.applyTheme("defaultV2");


export const ThemeComponent = (props: any) => {
    const onListTagsUser = [
        {title: "Producto", replace: 'PRODUCTO'},
        {title: "Usuario", replace: 'CLIENTE'}
    ]
    const onListTagsGuest = [
        {title: "Producto", replace: 'PRODUCTO'}
    ]
    const {estilo, onExternal} = props
    const {
        isAddNewGoodBye,
        isUpdateGoodBye,
        selectItem,
        listDataItem,
        setDataItem,
        setListDataItem,
        duplicateDataItem,
        deleteDataItem,
        changeNew,
        changeEdit,
        addNewDataItem,
        updateDataItem
    } = useThemeGoodBye()


    const [valoresPantallInicioFin, setValoresPantallInicioFin] = useState<ValoresPantallaInicioFin>(
        {
            textoInicioInvitado: "Hola esperamos que tu experiencia en #PRODUCTO# haya sido maravillosa, por favor cuéntanos más detalles para brindarte siempre un servicio de la más alta calidad.",
            textoInicioLogeado: "Estimado #CLIENTE# esperamos que tu experiencia en #PRODUCTO# haya sido memorable, por favor cuéntenos más detalles para brindarle siempre un servicio de la más alta calidad."
        } as ValoresPantallaInicioFin
    );
    const [itemStyle, setItemStyle] = useState<EstiloTexto>((props.estilo[0]["Cuerpo"] !== null && props.estilo[0]["Cuerpo"] !== "") ? JSON.parse(props.estilo[0]["Cuerpo"]) as EstiloTexto : {} as EstiloTexto);
    const [tabStyle, setTabStyle] = React.useState<string>("styleNav1");
    const [localidadIdSelected, setLocalidadIdSelected] = React.useState<string>(DEFAULT_GUID);
    const [preguntas] = React.useState<ColumnaFormulario[]>(getPregunstasFormulario(props.surveyData))
    let [location, setLocation] = React.useState<IListaSeleccion[]>([] as IListaSeleccion[])
    const [loading, setLoading] = useState<boolean>(true);
    let [tituloTema, setTituloTema] = useState<string>("")
    const [guestWelcomeText, setGuestWelcomeText] = useState<object>({});
    const [userWelcomeText, setUserWelcomeText] = useState<object>({});


    useEffect(() => {
        if (!loading) return

        let styleTmp = (JSON.parse(estilo[0]["Cuerpo"]) as EstiloTexto)
        let _listDataItem = styleTmp.itemsFinEncuesta ?? [] as NuevoItemFin[] 
        setListDataItem(_listDataItem) 

        setGuestWelcomeText(styleTmp.listWelcomeGuest || {})
        setUserWelcomeText(styleTmp.listWelcomeUser || {})

        // User
        setItemStyle(styleTmp)
        setTituloTema(props.estilo[0].Nombre)
        SetStyleCustom(styleTmp)
        setLocalidadIdSelected(props.estilo[0]["LocalidadId"])
                
        let valoresPantallInicioFinTmp = valoresPantallInicioFin
        setValoresPantallInicioFin(valoresPantallInicioFinTmp)
        getData().catch(()=>{})
        setLoading(false)
    }, []) 


    const getData = async () => {
        const wsLocation = await get(apiSelectionListByLocation);
        setLocation(wsLocation.value)
    }
    const setStyleProperty2 = (property: string, style: string) => {
        switch (property) {
            case "TASTH":
                itemStyle.textAlignSubTitleHeader = style
                break
            case "TWSTH":
                itemStyle.textWeightSubTitleHeader = style
                break
            case "TDSTH":
                itemStyle.textDecorationSubTitleHeader = style
                break
            case "TST":
                itemStyle.tabStyle = style
                break
            case "FBIN":
                itemStyle.backgroudColorFin = style
                break
            case "FCTI":
                itemStyle.colorFinInvitado = style
                break
            case "FCTL":
                itemStyle.colorFinLogeado = style
                break;
            case "FTAI":
                itemStyle.textAlignFinInvitado = style
                break
            case "FTAL":
                itemStyle.textAlignFinLogeado = style
                break;
            case "FTDI":
                itemStyle.textDecorationFinInvitado = style
                break;
            case "FTDL":
                itemStyle.textDecorationFinLogeado = style
                break;
            case "FTWI":
                itemStyle.textWeightFinInvitado = style
                break;
            case "FTWL":
                itemStyle.textWeightFinLogeado = style
                break;
            case 'SENB':
                itemStyle.backgroundNavigation = style
                break
            case 'SENBC':
                itemStyle.backgroundNavigationCurrent = style
                break
            case 'SENBP':
                itemStyle.backgroundNavigationPassed = style
                break
            case 'TTN':
                itemStyle.fontsizeNavigationPassed = style
                break
            case 'FCNV':
                itemStyle.fontColorNavigation = style
                break
            case 'FCINV':
                itemStyle.fontColorInactivoNavigation = style
                break
            case 'PFTX':
                itemStyle.colorPreguntaBotonFondo = style
                break
            case 'PCTX':
                itemStyle.colorPreguntaBotonTexto = style
                break
            case 'CCAF':
                itemStyle.colorCirculoAnimacionFin = style
                break
            case 'CCKAF':
                itemStyle.colorCheckAnimacionFin = style
                break
            case 'CTAF':
                itemStyle.colorTextoAnimacionFin = style
                break
            case 'CTRAN':
                itemStyle.colorTransiciones = style
                break
            case 'IMGAL':
                itemStyle.imageAlignHeader = style
                break
            case 'TPAG':
                itemStyle.colorTitlePage = style
                break
            case 'STPAG':
                itemStyle.colorSubTitlePage = style
                break
        }
        SetStyleCustom(itemStyle)
        let stle = props.estilo
        stle[0].Cuerpo = JSON.stringify(itemStyle)
        props.setEstiloSelec(stle)
    }

    const setStyleProperty = (property: string, style: string) => {
            console.log("property",property)
            switch (property) {
                case "BIN":
                    itemStyle.backgroudColorInicio = style
                    break
                case "CTI":
                    itemStyle.colorInicioInvitado = style
                    break
                case "CTL":
                    itemStyle.colorInicioLogeado = style
                    break;
                case "TAI":
                    itemStyle.textAlignInicioInvitado = style
                    break
                case "TAL":
                    itemStyle.textAlignInicioLogeado = style
                    break;
                case "TDI":
                    itemStyle.textDecorationInicioInvitado = style
                    break;
                case "TDL":
                    itemStyle.textDecorationInicioLogeado = style
                    break;
                case "TWI":
                    itemStyle.textWeightInicioInvitado = style
                    break;
                case "TWL":
                    itemStyle.textWeightInicioLogeado = style
                    break;
                case "CH":
                    itemStyle.colorHeader = style
                    break;
                case "CF":
                    itemStyle.colorFondo = style
                    break;
                case "BC":
                    itemStyle.colorCelda = style
                    break;
                case "TX":
                    itemStyle.colorTextoContenido = style
                    break
                case "FF":
                    itemStyle.fontFamily = style
                    break
                case "BCEL":
                    itemStyle.bordeCelda = style
                    break
                case "FS":
                    itemStyle.fontSize = style
                    break
                case "BBTN":
                    itemStyle.fondoBtn = style
                    break
                case "CBTN":
                    itemStyle.coloreTextBtn = style
                    break
                case "BBTNOV":
                    itemStyle.fondoBtnHover = style
                    break
                case "PF":
                    itemStyle.fondoQuestion = style
                    break
                case "HTC":
                    itemStyle.colorTitleHeader = style
                    break
                case "HSC":
                    itemStyle.colorSubtitleHeader = style
                    break
                case "TAQ":
                    itemStyle.textAlignQuestion = style
                    break
                case "TWQ":
                    itemStyle.textWeightQuestion = style
                    break
                case "TDQ":
                    itemStyle.textDecorationQuestion = style
                    break
                case "TATH":
                    itemStyle.textAlignTitleHeader = style
                    break
                case "TWTH":
                    itemStyle.textWeightTitleHeader = style
                    break
                case "TDTH":
                    itemStyle.textDecorationTitleHeader = style
                    break
                default:
                    setStyleProperty2(property, style)
                    return
            }
            console.log("itemStyle:",itemStyle)
            SetStyleCustom(itemStyle)
            let stle = props.estilo
            stle[0].Cuerpo = JSON.stringify(itemStyle)
            props.setEstiloSelec(stle)
        }    

    const saveTheme = () => {
        let itm = props.estilo[0]
        itm.Nombre = tituloTema
        if (props.localidadId !== undefined && props.localidadId !== null)
            itm.LocalidadId = props.localidadId.toString()

        itm.Cuerpo = JSON.stringify(itemStyle)
        if (props.estilo[0].EstiloId < 0 || props.estilo[0].EstiloId.toString()===Guid.EMPTY) {
            post('Estilo', transformDataNew(itm)).then((resp) => {
                let stle = props.estilo
                stle[0] = resp
                props.setEstiloSelec(stle)
                notifications({title: `Estilo Guardado`, message: 'Se ingresó correctamente'});
            }).catch((error: any) => {
                notifications({title: 'Falló el ingreso', message: `${error}`, toastType: 'error'});
            });
        } else {
            itm = props.estilo[0]
            itm.Cuerpo = JSON.stringify(itemStyle)
            put(`Estilo(${props.estilo[0].EstiloId})`, itm).then(() => {
                notifications({title: 'Estilo actualizado', message: 'Se actualizó correctamente'});
            }).catch((error: any) => notifications({
                title: 'Falló al actualizar',
                message: `${error}`,
                toastType: 'error'
            }));
        }
    }

    const transformDataNew = (data: any) => {
        const {
            Nombre,
            Encabezado,
            Cuerpo,
            PiePagina,
            FechaCreacion,
            FechaUltimaModificacion,
            UsuarioIdUltimaModificacion,
            UsuarioUltimaModificacion,
            UsuarioIdCreacion,
            UsuarioCreacion,
            Eliminado,
            LocalidadId
        } = data

        return {
            Nombre,
            Encabezado,
            Cuerpo,
            PiePagina,
            FechaCreacion,
            FechaUltimaModificacion,
            UsuarioIdUltimaModificacion,
            UsuarioUltimaModificacion,
            UsuarioIdCreacion,
            UsuarioCreacion,
            Eliminado,
            LocalidadId
        }
    }
    const onChange = React.useCallback(
		() => (event: ComboBoxChangeEvent) => {
            let item: ColumnaFormulario = event.target.value as ColumnaFormulario
            itemStyle.preguntaSeleccionada = JSON.stringify(item)
            let itemStyleTmp = itemStyle
            setItemStyle(itemStyleTmp)
        },
		[]
	);

    const onChangeLocalidad = React.useCallback(
		() => (event: ComboBoxChangeEvent) => {
            setLocalidadIdSelected(event.value)
        },
		[]
	);

    //// methods

    const updateState = (_itemStyle: any) => {
        let stle = props.estilo
        stle[0].Cuerpo = JSON.stringify(_itemStyle)
        props.setEstiloSelec(stle)
    }

    useEffect(() => {
        itemStyle.itemsFinEncuesta = listDataItem
        updateState(itemStyle)
    }, [listDataItem])

    useEffect(() => {
        itemStyle.listWelcomeGuest = guestWelcomeText
        updateState(itemStyle)
    }, [guestWelcomeText])

    useEffect(() => {
        itemStyle.listWelcomeUser = userWelcomeText
        updateState(itemStyle)
    }, [userWelcomeText])

    const handleOpenAddNew = () => {
        if(changeNew)
            changeNew(true)
        setDataItem({} as NuevoItemFin)
    }

    const handleCloseAddUpdate = React.useCallback(
		() => {
            if(changeNew)
                changeNew(false)
            if(changeEdit)
                changeEdit(false)
        },
		[]
	);

    const handleOpenUpdate = React.useCallback(        
		(dataItem: NuevoItemFin) => {
            if(changeEdit)
                changeEdit(true)
            setDataItem(dataItem)
        },
		[] 
	);

    const handleSendData = React.useCallback(
        (formData: NuevoItemFin) => {
            isUpdateGoodBye && updateDataItem && updateDataItem(formData);
            isAddNewGoodBye && addNewDataItem && addNewDataItem(formData);
        },
        [isUpdateGoodBye,isAddNewGoodBye]
    );

    return (
        <div className="page-content styleSurvey">
            <div className="titleTheme1">
                <div
                    className="btn_back"
                    onClick={() => props.closeEdit()}
                >
                    Galería de temas
                </div>
                / Editar tema
                <input
                    type="text"
                    className="titleTema"
                    value={tituloTema}
                    onChange={(e) => {
                        setTituloTema(e.target.value)
                    }}
                    placeholder="Crea tema nuevo"
                />
                {location !== undefined && location !== null && props.localidadId === undefined ? (
                    <ComboBox
                        id="ListaSeleccionId"
                        data={location}
                        name="ListaSeleccionId"
                        textField="Nombre"
                        key="ListaSeleccionId"
                        onChange={onChangeLocalidad}
                        clearButton={false} 
                        defaultValue={location.filter(x => x.ListaSeleccionId.toString() === localidadIdSelected).length > 0 ? location.filter(x => x.ListaSeleccionId.toString() === localidadIdSelected)[0] : {}}
                        className="combo-style-width"
                    />
                ) : ""}
            </div>
            <input className="radio" id="one" name="group" type="radio" defaultChecked={true}/>
            <input className="radio" id="two" name="group" type="radio"/>
            <input className="radio" id="three" name="group" type="radio"/>
            <div className="contentPageLeft-theme">
                <HerramientasThema
                    estilo={props.estilo}
                    setEstiloSelec={props.setEstiloSelec}
                    setItemStylePadre={setItemStyle}
                    setTabStyle={setTabStyle}
                    setStyleProperty={setStyleProperty}
                />
                <HerramientasPantallaInicio
                    estilo={props.estilo}
                    setEstiloSelec={props.setEstiloSelec}
                    setItemStylePadre={setItemStyle}
                    setStyleProperty={setStyleProperty}
                />
                <HerramientasPantallaFin
                    estilo={props.estilo}
                    setEstiloSelec={props.setEstiloSelec}
                    setItemStylePadre={setItemStyle}
                    setStyleProperty={setStyleProperty}
                />
            </div>
            <div className="contentPageRight contentCenter">
                <div className="warpper-theme">
                    <div className="tabs">
                        <label className="tab" id="one-tab" htmlFor="one">Mensaje de Bienvenida</label>
                        <label className="tab" id="two-tab" htmlFor="two">Encuesta</label>
                        <label className="tab" id="three-tab" htmlFor="three">Mensaje de Finalización</label>
                    </div>
                    <div className="panels">
                        <div className="panel" id="one-panel">
                            <div className="contentPanel">
                                <div className="titleBienvenida">Mensaje de bienvenida a la encuesta (EXPLORER):</div>
                                <div>
                                    {!loading && onExternal.listLanguage.map(
                                        (language: any, index: number) => (
                                            <MessageComponent
                                                key={`${language.Valor.toString()}-user`}
                                                language={language}
                                                onShow={index === 0}
                                                initial={valoresPantallInicioFin.textoInicioLogeado}
                                                values={userWelcomeText}
                                                inputName={"inicio-logeado"}
                                                tags={onListTagsUser}
                                            />
                                        ))
                                    }

                                </div>
                                <div className="titleBienvenida">
                                    Mensaje de bienvenida a la encuesta (INVITADO):
                                </div>
                                <div>
                                    {!loading && onExternal.listLanguage.map(
                                        (language: any, index: number) => ( 
                                            <MessageComponent
                                                key={`${language.Valor.toString()}-guest`}
                                                language={language}
                                                onShow={index == 0}
                                                initial={valoresPantallInicioFin.textoInicioInvitado}
                                                values={guestWelcomeText}
                                                inputName={"inicio-guest"}
                                                tags={onListTagsGuest}                                                
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={"panel " + tabStyle + " client-survey min-heigth-survey"} id="two-panel">
                            <div id="contenedor-encuesta" className="container container-theme container-gr">
                                {!loading && <SurveyRender {...props} />}
                            </div>
                        </div>
                        <div className="panel" id="three-panel">
                            <div className="contentPanel">
                                <div className="titleBienvenida">
                                    Mensaje predeterminado de finalización de la encuesta:
                                </div>
                                <div>
                                    <div className="titleBienvenida">Índice de recomendación (pregunta):</div>
                                    <div className="mensajeBienvenida">
                                        <ComboBox
                                            name="comboCondicion"
                                            clearButton={false}
                                            data={preguntas}
                                            textField="title"
                                            className="responsiveContent combiocond combo-preguntafinal"
                                            onChange={onChange}
                                            defaultValue={JSON.parse(itemStyle.preguntaSeleccionada ?? "{}")}
                                        />
                                    </div>
                                    <div className="titleBienvenida">
                                        Mensaje Personalizado:
                                        <div className="addRule psa-tmp" onClick={handleOpenAddNew}>
                                            Añadir Ítem
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="scrollTabContent">
                                        <div className="containerRuleTab">
                                            {listDataItem.map((item: NuevoItemFin) => (
                                                <FinalItem
                                                    key={"rv_" + item.Id}
                                                    item={item}
                                                    enableEdit={handleOpenUpdate}
                                                    copiarRegla={duplicateDataItem}
                                                    eliminarRegla={deleteDataItem}
                                                    onLanguage={onExternal.listLanguage}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentSidebarRight contentRight">
                <div className="herramientasTema">
                    <div
                        className="btnGaleriaTemas"
                        onClick={() => {
                            props.showPreview()
                        }}
                    >
                        Previsualización
                    </div>
                    <div
                        className="btnGaleriaTemas"
                        onClick={() => {
                            saveTheme()
                        }}
                    >
                        Guardar Tema
                    </div>
                </div>
            </div>

            {(isAddNewGoodBye || isUpdateGoodBye) && (
                <div>
                    <div className="positionrelative">
                        <FinalItemEdit
                            item={selectItem}
                            addNuevoItem={handleSendData}
                            onLanguage={onExternal.listLanguage}
                            onHandleCloseAddUpdate={handleCloseAddUpdate}
                        />
                    </div>
                </div>
            )}
        </div>

    )
}
