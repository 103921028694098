import React from 'react';
import { NuevoItemFin } from "../../../interfaces/estilo";

const FinalItem = (props: any) => {
    const [item] = React.useState<NuevoItemFin>(props.item as NuevoItemFin)
    const [show, setShow] = React.useState<boolean>(false)
    return (
        <>
            <div className="ruleTab">
                <div className="ruleHeader" onClick={() => setShow(!show)}>
                    <div>{item.Nombre}</div>
                    <div className="controlsRule">
                        <ul>
                            <li>
                                <img src={require('../../../assets/images/general/reglas/ic_edit.png')} alt="edit"
                                     onClick={() => {
                                         props.enableEdit(item)
                                     }}/></li>
                            <li>
                                <img src={require('../../../assets/images/general/reglas/ic_duplicate.png')} alt="copia"
                                     onClick={() => {
                                         props.copiarRegla(item)
                                     }}/></li>
                            <li>
                                <img src={require('../../../assets/images/general/reglas/ic_delete.png')} alt="eliminar"
                                     onClick={() => {
                                         props.eliminarRegla(item)
                                     }}/></li>
                        </ul>
                    </div>
                </div>
                <div className="ruleContentView" style={{display: show ? 'block' : 'none'}}>
                    <div className="ifContainer">
                        <div
                            className="ifCondition">Rango: <span>{item.RangoInicio}</span> al <span>{item.RangoFin}</span>
                        </div>
                    </div>
                    {props.onLanguage.map((language: any) => (
                        <>
                            <div className="ifCondition">{language.Nombre}</div>
                            <div className="__editor three-theme-backgroud">
                                <div className="thenCondition three-text-inv">
                                    {item.Textos ? item.Textos[language.Valor] : ''}
                                </div>
                            </div>
                        </>

                    ))}
                </div>
            </div>
        </>
    )
}


export default FinalItem
