import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { reactPlugin } from "./AppInsights";
import { msalConfig } from "./authConfig";
import store from "./store";


const version = process.env.REACT_APP_VERSION;
export const msalInstance = new PublicClientApplication(msalConfig);

const style = "background: #222; color: #D9A42f; font-size:24px";
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        // @ts-ignore
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log(JSON.stringify(event));
    }
    if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/"
        });
        sessionStorage.clear();
    }
});
if (process.env.REACT_APP_PRODUCTION === 'true') {
    console.log(`%c PROD`, style);
} else {
    console.log(`%c DEV`, style);
}
console.log(`%c Versión: ${version}`, style);

const app = (
    <BrowserRouter>
        <AppInsightsContext.Provider value={reactPlugin}>
            <Provider store={store}>
                <App pca={msalInstance}/>
            </Provider>
        </AppInsightsContext.Provider>
    </BrowserRouter>
)

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(app);

