import { GroupDescriptor, normalizeGroups, State } from "@progress/kendo-data-query";
import { Guid } from "guid-typescript";

export const findIndexToObject = (filters: Array<Object>, value: Object, condition: string) => {
    let indexCreate = filters.findIndex((x: any) => x.field === condition);
    if (indexCreate !== -1) filters[indexCreate] = value
    else filters.push(value)
    return filters
}

export const normalizeGroup = (groups: GroupDescriptor[]) => {
    groups = groups.filter(
        (value: GroupDescriptor, index: number, self: GroupDescriptor[]) =>
            index === self.findIndex(
                (t: GroupDescriptor) => (
                    t.field === value.field
                )
            )
    )
    return groups
}

export const normalizeSort = (state: State, exclude: string[] = []) => {
    if (state.group?.length) {
        let groups = normalizeGroup(state.group)
        if (exclude && exclude.length > 0) {
            groups = groups.filter((group: GroupDescriptor) => !exclude.includes(group.field))
        }
        let sort = state.sort ?? [];
        if (sort?.length) {
            let tmpSort = sort[0];
            let currentGroup = groups.filter((group: GroupDescriptor) => group.field !== tmpSort.field || !exclude.includes(group.field))
            return normalizeGroups(currentGroup).concat(sort).concat({field: 'FechaCreacion', dir: 'desc'})
        }
        return normalizeGroups(groups).concat({field: 'FechaCreacion', dir: 'desc'})
    }
    return state.sort
}

export const normalizeTitle = (title: any) => {
    if (typeof title === 'object') {
        return title["es"] || title["default"] || title["en"]
    }
    return title
}

export const AddIgnoreCase = (filters: any, fields: Array<string>) => {
    return filters.map((filter: any) => {
        if (filter.filters) {
            return {...filter, filters: AddIgnoreCase(filter.filters, fields)}
        } else {
            let findField = fields.find(f => f === filter.field)
            if (findField) return {...filter, ignoreCase: true}
            else return {...filter}
        }
    })
}

export const normalizeFilterByLocalidad = (onDataState: any, onLocalidad: Guid | undefined) => {
    let filters = onDataState.filter ? onDataState.filter.filters : [];
    let logic = onDataState.filter ? onDataState.filter.logic : 'and';
    let objFilterLocalidad = {field: "LocalidadId", operator: "eq", value: onLocalidad}
    filters = findIndexToObject(filters, objFilterLocalidad, "LocalidadId")
    return {'logic': logic, 'filters': filters}
}
