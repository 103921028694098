import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { getValueByDefault, styleDefault } from "../../../components/Common/Survey/Utils";
import { optionsFontSize } from "../../../helpers/smartSurvey";
import { EstiloTexto } from "../../../interfaces/estilo";

const HerramientasThema = (props: any) => {
    const [loadInfo, setLoadInfo] = useState<boolean>(true);
    const [backgroudBtn, setBackgroudBtn] = useState<string>("");
    const [backgroudBtnOver, setBackgroudBtnOver] = useState<string>("");
    const [bodybackgroundColor, setBodybackgroundColor] = useState<string>("");
    const [borderCell, setBorderCell] = useState<string>("");
    const [colorBTN, setColorBTN] = useState<string>("");
    const [colorSubTitleHeader, setColorSubTitleHeader] = useState<string>("");
    const [colorTitleHeader, setColorTitleHeader] = useState<string>("");
    const [fontFamily, setFontFamily] = useState<string>("");
    const [fontSize, setFontSize] = useState<string>("");
    const [tabStyle, setTabStyle] = useState<string>("styleNav1");

    const [headerbackgroundColor, setHeaderbackgroundColor] = useState<string>("");
    const [questionFondo, setQuestionFondo] = useState<string>("");
    const [textAlignQuestion, setTextAlignQuestion] = useState<string>("");
    const [textAlignSubTitleHeader, setTextAlignSubTitleHeader] = useState<string>("");
    const [textAlignTitleHeader, setTextAlignTitleHeader] = useState<string>("");
    const [textColor, setTextColor] = useState<string>("");
    const [textDecorationQuestion, setTextDecorationQuestion] = useState<string>("");
    const [textDecorationSubTitleHeader, setTextDecorationSubTitleHeader] = useState<string>("");
    const [textDecorationTitleHeader, setTextDecorationTitleHeader] = useState<string>("");
    const [textWeightQuestion, setTextWeightQuestion] = useState<string>("");
    const [textWeightSubTitleHeader, setTextWeightSubTitleHeader] = useState<string>("");
    const [textWeightTitleHeader, setTextWeightTitleHeader] = useState<string>("");

    const [backgroundNavigation, setBackgroundNavigation] = useState<string>(styleDefault.backgroundNavigation)
    const [backgroundNavigationCurrent, setBackgroundNavigationCurrent] = useState<string>(styleDefault.backgroundNavigationCurrent)
    const [backgroundNavigationPassed, setBackgroundNavigationPassed] = useState<string>(styleDefault.backgroundNavigationPassed)
    const [fontsizeNavigationPassed, setFontsizeNavigationPassed] = useState<string>(styleDefault.fontsizeNavigationPassed)
    const [fontColorNavigation, setFontColorNavigation] = useState<string>(styleDefault.fontColorNavigation)
    const [fontColorInactivoNavigation, setFontColorInactivoNavigation] = useState<string>(styleDefault.fontColorInactivoNavigation)
    const [colorPreguntaBotonFondo, setColorPreguntaBotonFondo] = useState<string>(styleDefault.colorPreguntaBotonFondo)
    const [colorPreguntaBotonTexto, setColorPreguntaBotonTexto] = useState<string>(styleDefault.colorPreguntaBotonTexto)

    const [colorCirculoAnimacionFin, setColorCirculoAnimacionFin] = useState<string>(styleDefault.colorCirculoAnimacionFin)
    const [colorCheckAnimacionFin, setColorCheckAnimacionFin] = useState<string>(styleDefault.colorCheckAnimacionFin)
    const [colorTextoAnimacionFin, setColorTextoAnimacionFin] = useState<string>(styleDefault.colorTextoAnimacionFin)
    const [colorTransiciones, setColorTransiciones] = useState<string>(styleDefault.colorTransiciones)
    const [imageAlignHeader, setImageAlignHeader] = useState<string>(styleDefault.imageAlignHeader)

    const [colorTitlePage, setColorTitlePage] = useState<string>(styleDefault.colorTitlePage)
    const [colorSubTitlePage, setColorSubTitlePage] = useState<string>(styleDefault.colorSubTitlePage)


    let [itemStyle, setItemStyle] = useState<EstiloTexto>({} as EstiloTexto);

    const typeStyleList = [
        "General",
        "Preguntas",
        "Botones",
        "Páginas"
    ];
    const [typeStyle, setTypeStyle] = React.useState<string>("General")


    useEffect(() => {
        if (!loadInfo) return
        setLoadInfo(false)


        let styleTmp: EstiloTexto = (JSON.parse(props.estilo[0]["Cuerpo"]) as EstiloTexto)
        itemStyle = styleTmp
        setItemStyle(styleTmp)

        setStyle("CH", styleTmp.colorHeader)
        setStyle("CF", styleTmp.colorFondo)
        setStyle("BC", styleTmp.colorCelda)
        setStyle("TX", styleTmp.colorTextoContenido)
        setStyle("FF", styleTmp.fontFamily)
        setStyle("BCEL", styleTmp.bordeCelda)
        setStyle("FS", styleTmp.fontSize)
        setStyle("BBTN", styleTmp.fondoBtn)
        setStyle("CBTN", styleTmp.coloreTextBtn)
        setStyle("BBTNOV", styleTmp.fondoBtnHover)
        setStyle("PF", styleTmp.fondoQuestion)
        setStyle("HTC", styleTmp.colorTitleHeader)
        setStyle("HSC", styleTmp.colorSubtitleHeader)

        setStyle("TAQ", styleTmp.textAlignQuestion)
        setStyle("TWQ", styleTmp.textWeightQuestion)
        setStyle("TDQ", styleTmp.textDecorationQuestion)

        setStyle("TATH", styleTmp.textAlignTitleHeader)
        setStyle("TWTH", styleTmp.textWeightTitleHeader)
        setStyle("TDTH", styleTmp.textDecorationTitleHeader)

        setStyle("TASTH", styleTmp.textAlignSubTitleHeader)
        setStyle("TWSTH", styleTmp.textWeightSubTitleHeader)
        setStyle("TDSTH", styleTmp.textDecorationSubTitleHeader)

        setStyle("TST", styleTmp.tabStyle)

        setStyle("SENB", getValueByDefault(styleTmp.backgroundNavigation, styleDefault.backgroundNavigation))
        setStyle("SENBC", getValueByDefault(styleTmp.backgroundNavigationCurrent, styleDefault.backgroundNavigationCurrent))
        setStyle("SENBP", getValueByDefault(styleTmp.backgroundNavigationPassed, styleDefault.backgroundNavigationPassed))
        setStyle("TTN", getValueByDefault(styleTmp.fontsizeNavigationPassed, styleDefault.fontsizeNavigationPassed))
        setStyle("FCNV", getValueByDefault(styleTmp.fontColorNavigation, styleDefault.fontColorNavigation))
        setStyle("FCINV", getValueByDefault(styleTmp.fontColorInactivoNavigation, styleDefault.fontColorInactivoNavigation))
        setStyle("PFTX", getValueByDefault(styleTmp.colorPreguntaBotonFondo, styleDefault.colorPreguntaBotonFondo))
        setStyle("PCTX", getValueByDefault(styleTmp.colorPreguntaBotonTexto, styleDefault.colorPreguntaBotonTexto))


        setStyle("CCAF", getValueByDefault(styleTmp.colorCirculoAnimacionFin, styleDefault.colorCirculoAnimacionFin))
        setStyle("CCKAF", getValueByDefault(styleTmp.colorCheckAnimacionFin, styleDefault.colorCheckAnimacionFin))
        setStyle("CTAF", getValueByDefault(styleTmp.colorTextoAnimacionFin, styleDefault.colorTextoAnimacionFin))
        setStyle("CTRAN", getValueByDefault(styleTmp.colorTransiciones, styleDefault.colorTransiciones))

        setStyle("IMGAL", getValueByDefault(styleTmp.imageAlignHeader, styleDefault.imageAlignHeader))

        setStyle("TPAG", getValueByDefault(styleTmp.colorTitlePage, styleDefault.colorTitlePage))
        setStyle("STPAG", getValueByDefault(styleTmp.colorSubTitlePage, styleDefault.colorSubTitlePage))


    })

    const setStyle2 = (tipo: string, style: string) => {
        switch (tipo) {
            case 'TPAG':
                itemStyle.colorTitlePage = style
                setColorTitlePage(style)
                break
            case 'STPAG':
                itemStyle.colorSubTitlePage = style
                setColorSubTitlePage(style)
                break
            case 'PFTX':
                itemStyle.colorPreguntaBotonFondo = style
                setColorPreguntaBotonFondo(style)
                break
            case 'PCTX':
                itemStyle.colorPreguntaBotonTexto = style
                setColorPreguntaBotonTexto(style)
                break
            case 'CCAF':
                itemStyle.colorCirculoAnimacionFin = style
                setColorCirculoAnimacionFin(style)
                break
            case 'CCKAF':
                itemStyle.colorCheckAnimacionFin = style
                setColorCheckAnimacionFin(style)
                break
            case 'CTAF':
                itemStyle.colorTextoAnimacionFin = style
                setColorTextoAnimacionFin(style)
                break
            case 'CTRAN':
                itemStyle.colorTransiciones = style
                setColorTransiciones(style)
                break
            case 'IMGAL':
                itemStyle.imageAlignHeader = style
                setImageAlignHeader(style)
                break
        }
        props.setStyleProperty(tipo, style)
    }
    const setStyle = (tipo: string, style: string) => {
        switch (tipo) {
            case "CH":
                itemStyle.colorHeader = style
                setHeaderbackgroundColor(style)
                break;
            case "CF":
                itemStyle.colorFondo = style
                setBodybackgroundColor(style)
                break;
            case "BC":
                itemStyle.colorCelda = style
                break;
            case "TX":
                itemStyle.colorTextoContenido = style
                setTextColor(style)
                break
            case "FF":
                itemStyle.fontFamily = style
                setFontFamily(style)
                break
            case "BCEL":
                itemStyle.bordeCelda = style
                setBorderCell(style)
                break
            case "FS":
                itemStyle.fontSize = style
                setFontSize(style)
                break
            case "BBTN":
                itemStyle.fondoBtn = style
                setBackgroudBtn(style)
                break
            case "CBTN":
                itemStyle.coloreTextBtn = style
                setColorBTN(style)
                break
            case "BBTNOV":
                itemStyle.fondoBtnHover = style
                setBackgroudBtnOver(style)
                break
            case "PF":
                itemStyle.fondoQuestion = style
                setQuestionFondo(style)
                break
            case "HTC":
                itemStyle.colorTitleHeader = style
                setColorTitleHeader(style)
                break
            case "HSC":
                itemStyle.colorSubtitleHeader = style
                setColorSubTitleHeader(style)
                break
            case "TAQ":
                itemStyle.textAlignQuestion = style
                setTextAlignQuestion(style)
                break
            case "TWQ":
                if (itemStyle.textWeightQuestion === style)
                    style = "none"
                itemStyle.textWeightQuestion = style
                setTextWeightQuestion(style)
                break
            case "TDQ":
                if (itemStyle.textDecorationQuestion === style)
                    style = "none"

                itemStyle.textDecorationQuestion = style
                setTextDecorationQuestion(style)
                break
            case "TATH":
                itemStyle.textAlignTitleHeader = style
                setTextAlignTitleHeader(style)

                break
            case "TWTH":
                if (itemStyle.textWeightTitleHeader === style)
                    style = "none"
                itemStyle.textWeightTitleHeader = style
                setTextWeightTitleHeader(style)
                break
            case "TDTH":
                if (itemStyle.textDecorationTitleHeader === style)
                    style = "none"

                itemStyle.textDecorationTitleHeader = style
                setTextDecorationTitleHeader(style)
                break
            case "TASTH":
                itemStyle.textAlignSubTitleHeader = style
                setTextAlignSubTitleHeader(style)
                break
            case "TWSTH":
                if (itemStyle.textWeightSubTitleHeader === style)
                    style = "none"
                itemStyle.textWeightSubTitleHeader = style
                setTextWeightSubTitleHeader(style)
                break
            case "TDSTH":
                if (itemStyle.textDecorationSubTitleHeader === style)
                    style = "none"
                itemStyle.textDecorationSubTitleHeader = style
                setTextDecorationSubTitleHeader(style)
                break
            case "TST":
                itemStyle.tabStyle = style
                setTabStyle(style)
                props.setTabStyle(style)
                props.setStyleProperty(tipo, style)
                break
            case 'SENB':
                itemStyle.backgroundNavigation = style
                setBackgroundNavigation(style)
                break
            case 'SENBC':
                itemStyle.backgroundNavigationCurrent = style
                setBackgroundNavigationCurrent(style)
                break
            case 'SENBP':
                itemStyle.backgroundNavigationPassed = style
                setBackgroundNavigationPassed(style)
                break
            case 'TTN':
                itemStyle.fontsizeNavigationPassed = style
                setFontsizeNavigationPassed(style)
                break
            case 'FCNV':
                itemStyle.fontColorNavigation = style
                setFontColorNavigation(style)
                break
            case 'FCINV':
                itemStyle.fontColorInactivoNavigation = style
                setFontColorInactivoNavigation(style)
                break
            default:
                setStyle2(tipo, style)
                return
        }
        props.setStyleProperty(tipo, style)
    }


    const handleChangeTypeStyle = (event: DropDownListChangeEvent) => {
        if (event) {
            setTypeStyle(event.value)
        }
    }

    const getStyleAling = (style: string) => {
        if (imageAlignHeader === style)
            return "k-selected"
        return "";
    }

    return (
        <div className="herramientasTema herramientas-tema-two">
            <div className="scrollHerramientas">
                <DropDownList
                    className="selector"
                    data={typeStyleList}
                    defaultValue={typeStyle}
                    onChange={handleChangeTypeStyle}
                />
                <div className="scrollHerramientas">
                    {typeStyle === "General" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="color-header" className="activator"/>
                                <h1><label htmlFor="color-header">Header</label></h1>
                                <div className="p">
                                    <p className="titleSection">Color de fondo:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker" value={headerbackgroundColor}
                                                       onChange={(e: any) => setStyle("CH", e.target.value)}/>
                                                {headerbackgroundColor}
                                            </span>
                                    </p>
                                    <p className="titleSection">Color de titulo:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker"
                                                       value={colorTitleHeader}
                                                       onChange={(e: any) => setStyle("HTC", e.target.value)}/>
                                                {colorTitleHeader}
                                            </span>
                                    </p>
                                    <p className="titleSection">Herramientas para orientación titulo:</p>
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={textAlignTitleHeader === 'left' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TATH", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={textAlignTitleHeader === 'center' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TATH", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={textAlignTitleHeader === 'right' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TATH", 'right')}/>
                                    </p>
                                    <p>
                                        <Button iconClass="k-icon k-i-bold"
                                                className={textWeightTitleHeader === 'bold' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TWTH", 'bold')}/>
                                        <Button iconClass="k-icon k-i-underline"
                                                className={textDecorationTitleHeader === 'underline' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TDTH", 'underline')}/>
                                    </p>
                                    <p className="titleSection">Color Subtitulo:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker"
                                                       value={colorSubTitleHeader}
                                                       onChange={(e: any) => setStyle("HSC", e.target.value)}/>
                                                {colorSubTitleHeader}
                                            </span>
                                    </p>
                                    <p className="titleSection">Herramientas para orientación subtitulo:</p>
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={textAlignSubTitleHeader === 'left' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TASTH", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={textAlignSubTitleHeader === 'center' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TASTH", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={textAlignSubTitleHeader === 'right' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TASTH", 'right')}/>
                                    </p>
                                    <p>
                                        <Button iconClass="k-icon k-i-bold"
                                                className={textWeightSubTitleHeader === 'bold' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TWSTH", 'bold')}/>
                                        <Button iconClass="k-icon k-i-underline"
                                                className={textDecorationSubTitleHeader === 'underline' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TDSTH", 'underline')}/>
                                    </p>
                                    <p className="titleSection">Orientación Logo:</p>
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={getStyleAling('left')}
                                                onClick={(_e: any) => setStyle("IMGAL", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={getStyleAling('center')}
                                                onClick={(_e: any) => setStyle("IMGAL", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={getStyleAling('right')}
                                                onClick={(_e: any) => setStyle("IMGAL", 'right')}/>
                                    </p>
                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="color-fondo" className="activator"/>
                                <h1><label htmlFor="color-fondo">Fondo de encuesta</label></h1>
                                <div className="p">
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker" value={bodybackgroundColor}
                                                       onChange={(e: any) => setStyle("CF", e.target.value)}/>
                                                {bodybackgroundColor}
                                            </span>
                                    </p>
                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="fuenteEnc1" className="activator"/>
                                <h1><label htmlFor="fuenteEnc1">Fuente</label></h1>
                                <div className="p">
                                    <p className="titleSection">Tipo de letra:</p>
                                    <p>
                                        <select className="sizeLarge comboClick" value={fontFamily}
                                                onChange={(e: any) => setStyle("FF", e.target.value)}>
                                            <option>MS Serif</option>
                                            <option>Arial</option>
                                            <option>Courier New</option>
                                            <option>Georgia</option>
                                            <option>Tahoma</option>
                                            <option>Times New Roman</option>
                                            <option>Trebuchet MS</option>
                                            <option>Verdana</option>
                                            <option>Open Sans</option>
                                            <option>Lato</option>
                                            <option>Droid Serift</option>
                                        </select>
                                    </p>
                                    <p className="titleSection">Tamaño:</p>
                                    <p>
                                        <select className="sizeLarge comboClick" value={fontSize}
                                                onChange={(e: any) => setStyle("FS", e.target.value)}>
                                            {optionsFontSize.map(item => {
                                                return (<option key={item.text} value={item.text}>{item.text}</option>);
                                            })}
                                        </select>
                                    </p>
                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="tabEstilo" className="activator"/>
                                <h1><label htmlFor="tabEstilo">Navegación</label></h1>
                                <div className="p">
                                    <p className="titleSection">Estilo:</p>
                                    <p>
                                        <select className="sizeLarge comboClick" value={tabStyle}
                                                onChange={(e: any) => setStyle("TST", e.target.value)}>
                                            <option value="styleNav1">Estilo1</option>
                                            <option value="styleNav2">Estilo2</option>
                                            <option value="styleNav3">Estilo3</option>
                                            <option value="styleNav4">Estilo4</option>
                                        </select>
                                    </p>
                                    <p className="titleSection">Tamaño fuente:</p>
                                    <p>
                                        <select className="sizeLarge comboClick" value={fontsizeNavigationPassed}
                                                onChange={(e: any) => setStyle("TTN", e.target.value)}>
                                            {optionsFontSize.map(item => {
                                                return (<option key={item.text} value={item.text}>{item.text}</option>);
                                            })}
                                        </select>
                                    </p>
                                    <p className="titleSection">Color de fuente activo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpickerFcnv" value={fontColorNavigation}
                                                   onChange={(e: any) => setStyle("FCNV", e.target.value)}/>
                                            {fontColorNavigation}
                                        </span>
                                    </p>
                                    <p className="titleSection">Color de fuente inactivo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpickerFcnv" value={fontColorInactivoNavigation}
                                                   onChange={(e: any) => setStyle("FCINV", e.target.value)}/>
                                            {fontColorInactivoNavigation}
                                        </span>
                                    </p>
                                    <p className="titleSection">Color de fondo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker" value={backgroundNavigation}
                                                   onChange={(e: any) => setStyle("SENB", e.target.value)}/>
                                            {backgroundNavigation}
                                        </span>
                                    </p>
                                    <p className="titleSection">Color de fondo activo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker" value={backgroundNavigationCurrent}
                                                   onChange={(e: any) => setStyle("SENBC", e.target.value)}/>
                                            {backgroundNavigationCurrent}
                                        </span>
                                    </p>
                                    <p className="titleSection">Color de fondo contestadas:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker" value={backgroundNavigationPassed}
                                                   onChange={(e: any) => setStyle("SENBP", e.target.value)}/>
                                            {backgroundNavigationPassed}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="tabFinEncuesta" className="activator"/>
                                <h1><label htmlFor="tabFinEncuesta">Fin encuesta</label></h1>
                                <div className="p">
                                    <p className="titleSection">Circulo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpickerFcnv" value={colorCirculoAnimacionFin}
                                                   onChange={(e: any) => setStyle("CCAF", e.target.value)}/>
                                            {colorCirculoAnimacionFin}
                                        </span>
                                    </p>
                                    <p className="titleSection">Check:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpickerFcnv" value={colorCheckAnimacionFin}
                                                   onChange={(e: any) => setStyle("CCKAF", e.target.value)}/>
                                            {colorCheckAnimacionFin}
                                        </span>
                                    </p>
                                    <p className="titleSection">Texto:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpickerFcnv" value={colorTextoAnimacionFin}
                                                   onChange={(e: any) => setStyle("CTAF", e.target.value)}/>
                                            {colorTextoAnimacionFin}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="tabFinTran" className="activator"/>
                                <h1><label htmlFor="tabFinTran">Transiciones</label></h1>
                                <div className="p">
                                    <p className="titleSection">Circulo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpickerFcnv" value={colorTransiciones}
                                                   onChange={(e: any) => setStyle("CTRAN", e.target.value)}/>
                                            {colorTransiciones}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {typeStyle === "Preguntas" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="fuentePre" className="activator"/>
                                <h1>
                                    <label htmlFor="fuentePre">Colores</label>
                                </h1>
                                <div className="p">
                                    <p className="titleSection">Fondo recuadro:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker"
                                                       value={questionFondo}
                                                       onChange={(e: any) => setStyle("PF", e.target.value)}/>
                                                {questionFondo}
                                            </span>
                                    </p>
                                    <p className="titleSection">Color texto respuesta:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker"
                                                       value={textColor}
                                                       onChange={(e: any) => setStyle("TX", e.target.value)}/>
                                                {textColor}
                                            </span>
                                    </p>
                                    <p className="titleSection">Borde recuadro:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpickerbr"
                                                       value={borderCell}
                                                       onChange={(e: any) => setStyle("BCEL", e.target.value)}/>
                                                {borderCell}
                                            </span>
                                    </p>
                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="align" className="activator"/>
                                <h1>
                                    <label htmlFor="align">Herramientas para orientación</label>
                                </h1>
                                <div className="p">
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={textAlignQuestion === 'left' ? 'k-selected' : ''}
                                                onClick={() => setStyle("TAQ", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={textAlignQuestion === 'center' ? 'k-selected' : ''}
                                                onClick={() => setStyle("TAQ", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={textAlignQuestion === 'right' ? 'k-selected' : ''}
                                                onClick={() => setStyle("TAQ", 'right')}/>
                                    </p>
                                    <p>
                                        <Button iconClass="k-icon k-i-bold"
                                                className={textWeightQuestion === 'bold' ? 'k-selected' : ''}
                                                onClick={() => setStyle("TWQ", 'bold')}/>
                                        <Button iconClass="k-icon k-i-underline"
                                                className={textDecorationQuestion === 'underline' ? 'k-selected' : ''}
                                                onClick={() => setStyle("TDQ", 'underline')}/>
                                    </p>

                                </div>
                            </div>
                            <div className="c">
                                <input type="checkbox" id="ctlButton" className="activator"/>
                                <h1>
                                    <label htmlFor="ctlButton">Control botones</label>
                                </h1>
                                <div className="p">
                                    <p className="titleSection">Fondo seleccionado:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpickerPftx"
                                                       value={colorPreguntaBotonFondo}
                                                       onChange={(e: any) => setStyle("PFTX", e.target.value)}/>
                                                {colorPreguntaBotonFondo}
                                            </span>
                                    </p>
                                    <p className="titleSection">Texto seleccionado:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpickerPctx"
                                                       value={colorPreguntaBotonTexto}
                                                       onChange={(e: any) => setStyle("PCTX", e.target.value)}/>
                                                {colorPreguntaBotonTexto}
                                            </span>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {typeStyle === "Botones" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="botones" className="activator"/>
                                <h1><label htmlFor="botones">Colores</label></h1>
                                <div className="p">
                                    <p className="titleSection">Fondo:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker"
                                                       value={backgroudBtn}
                                                       onChange={(e: any) => setStyle("BBTN", e.target.value)}/>
                                                {backgroudBtn}
                                            </span>
                                    </p>
                                    <p className="titleSection">Fondo Over:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpickerOv"
                                                       value={backgroudBtnOver}
                                                       onChange={(e: any) => setStyle("BBTNOV", e.target.value)}/>
                                                {backgroudBtnOver}
                                            </span>
                                    </p>
                                    <p className="titleSection">Color texto:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpickerbr"
                                                       value={colorBTN}
                                                       onChange={(e: any) => setStyle("CBTN", e.target.value)}/>
                                                {colorBTN}
                                            </span>
                                    </p>

                                </div>
                            </div>
                        </>
                    )}
                    {typeStyle === "Páginas" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="paginaR" className="activator"/>
                                <h1><label htmlFor="paginaR">Colores</label></h1>
                                <div className="p">
                                    <p className="titleSection">Título:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker"
                                                       value={colorTitlePage}
                                                       onChange={(e: any) => setStyle("TPAG", e.target.value)}/>
                                                {colorTitlePage}
                                            </span>
                                    </p>
                                    <p className="titleSection">Subtítulo:</p>
                                    <p>
                                            <span className="relative-pos">
                                                <input type="color" id="colorpicker"
                                                       value={colorSubTitlePage}
                                                       onChange={(e: any) => setStyle("STPAG", e.target.value)}/>
                                                {colorSubTitlePage}
                                            </span>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
export default HerramientasThema
