import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import ic_bell from '../../assets/images/general/iconos/bell.svg';

import ic_configList from '../../assets/images/general/iconos/ic_configList.svg';
import ic_settings from '../../assets/images/general/iconos/ic_configuration.svg';
import ic_dashboard from '../../assets/images/general/iconos/ic_dashboard.svg';
import ic_feed from '../../assets/images/general/iconos/ic_feed.svg';
import ic_form from '../../assets/images/general/iconos/ic_form.svg';
import ic_home from '../../assets/images/general/iconos/ic_home.svg';
import ic_product from '../../assets/images/general/iconos/ic_product.svg';
import ic_userEdit from '../../assets/images/general/iconos/ic_userEdit.svg';
import ic_send from '../../assets/images/general/iconos/send.svg';
import { apiUsuarioLocalidadByUsuario } from "../../constants/api"
import { CargarEstadosEncuesta, CargarTipoEncuesta } from "../../constants/default";
import { get } from "../../helpers/api_helper"
import { notifications } from "../../helpers/notifications"
import { hasPermissions, replaceObjectInString } from "../../helpers/utils"
import { msalInstance } from "../../index";
import { ROLE } from "../../interfaces/roles";


type SideBarCountryLiProps = {
    link: string,
    icon: string,
    title: string,
}

class SideBarCountryLi extends React.Component<SideBarCountryLiProps> {
    render() {
        const {link, icon, title} = this.props;
        return (
            <li>
                <NavLink className="dropdown-item" to={link} title={title}>
                    <span>
                        <img src={icon} alt="icon" className="sidebarIcon "></img>
                    </span>
                    {title}
                </NavLink>
            </li>
        )
    }
  }

const Navbar = (props: any) => {

    const [sideBar, setSideBar] = useState([]);

    useEffect(() => {
        const params = {user: msalInstance.getActiveAccount()?.username}
        const url = replaceObjectInString(apiUsuarioLocalidadByUsuario, params);
        if (localStorage.getItem("usr_localidad") === null) {
            get(url).then(odata => {
                setSideBar(odata.value)
                localStorage.setItem("usr_localidad", JSON.stringify(odata.value))
            }).catch(error => {
                notifications({title: 'Error', message: `${error}`, toastType: 'error'});
            });
        } else {
            const listJson = localStorage.getItem('usr_localidad');            
            setSideBar(listJson !== null ? JSON.parse(listJson) : []);
        }

        CargarEstadosEncuesta()
        CargarTipoEncuesta()
    }, []);


    const closeMenu = (id: string) => {
        if (id != null) {
            let element = document.getElementById(id)

            if (element != null) {
                if (element.style.height === "30px")
                    element.style.height = "auto";
                else
                    element.style.height = "30px";
            }
        }
    }
    const renderSideBarItemsAdmin = () => {
        return <>
            <ul key={"confirMenu"} className="toolList sidebarMenu">
                <li>
                    <NavLink to={"/"} title="Lista selección">
                        <div className="homeMenu" title="Inicio">
                            <span><img src={ic_home} alt="Home" className="sidebarIcon"></img></span>Inicio
                        </div>
                    </NavLink>
                </li>
                <li id="lnConfig">
                    {hasPermissions([ROLE.AdministradorSistema, ROLE.VisorListaSeleccion, ROLE.VisorProducto, ROLE.VisorUsuarioLocalidad]) ?
                        <div className="primaryMenu configIcon" title="Configuración"
                             onClick={() => closeMenu("lnConfig")}>
                            <span><img src={ic_settings} alt="icon" className="sidebarIcon"></img></span> Configuración
                        </div> : ""}
                    <ul>
                        {hasPermissions([ROLE.AdministradorSistema, ROLE.VisorListaSeleccion]) ? <li>
                            <NavLink className="dropdown-item" to={"/lista-seleccion"} title="Lista selección">
                                <span className="svgPosition"><img src={ic_configList} alt="icon"
                                                                   className="sidebarIcon"></img></span>
                                Catálogo
                            </NavLink>
                        </li> : ""}                        
                        {hasPermissions([ROLE.AdministradorSistema, ROLE.VisorProducto]) ? <li>
                            <NavLink className="dropdown-item" to="/producto" title="Producto">
                                <span><img src={ic_product} alt="icon" className="margin-left-image sidebarIcon"></img></span>
                                Producto
                            </NavLink>
                        </li> : ""}
                        {hasPermissions([ROLE.AdministradorSistema, ROLE.VisorUsuarioLocalidad]) ? <li>
                            <NavLink className="dropdown-item" to="/usuario-localidad" title="Usuario localidad">
                                <span className="svgPosition"><img src={ic_userEdit} alt="icon"
                                                                   className="sidebarIcon"></img></span>
                                Usuario localidad
                            </NavLink>
                        </li> : ""}
                        {hasPermissions([]) ? <li>
                            <NavLink className="dropdown-item" to="/configuracion-chat-gpt" title="Configuracion chat gpt">
                                <span className="svgPosition"><img src={ic_settings} alt="icon"
                                                                   className="sidebarIcon"></img></span>
                                Configuración ChatGPT
                            </NavLink>
                        </li> : ""}
                    </ul>
                </li>
            </ul>
        </>
    }


    

    const renderSideBarItems = (items: any) => {
        return <>
            {renderSideBarItemsAdmin()}
            {items.map((item: any) => {
                return (
                    <ul key={item.UsuarioLocalidadId} className="toolList sidebarMenu">
                        <li id={"lnPais_" + item.UsuarioLocalidadId}>
                            <div className="primaryMenu" title={item.Localidad.Nombre} onClick={() => closeMenu("lnPais_" + item.UsuarioLocalidadId)}>
                                <span>
                                    <img src={require('../../assets/images/pais/' + item.Localidad.Valor + ".png")} alt="icon" className="flagIcon"></img>
                                </span> 
                                {item.Localidad.Nombre}
                            </div>
                            <ul>
                                {hasPermissions([ROLE.AdministradorSistema, ROLE.Dashboard]) && 
                                    <SideBarCountryLi  link={"/dashboard/" + item.LocalidadId} icon={ic_dashboard} title={"Dashboard"} />
                                }
                                {hasPermissions([ROLE.AdministradorSistema, ROLE.EditarFormulario, ROLE.VisorFormularioActivo, ROLE.VisorFormularioEliminado]) &&
                                    <SideBarCountryLi  link={"/formularios/" + item.LocalidadId} icon={ic_form} title={"Formularios"} />
                                } 
                                {hasPermissions([]) &&
                                    <SideBarCountryLi  link={"/analisis-sentimientos/" + item.LocalidadId} icon={ic_form} title={"Analisis de sentimiento"} />
                                }
                                {hasPermissions([ROLE.AdministradorSistema, ROLE.GestionFeedback, ROLE.GestionFeedbackResponsable]) &&
                                    <SideBarCountryLi  link={"/closethelopp/abiertos/" + item.LocalidadId} icon={ic_feed} title={"Gestión de Feedback"} />
                                }
                                {hasPermissions([ROLE.AdministradorSistema, ROLE.CanalEnvio, ROLE.VisorCanalEnvio]) ?
                                    <li id={"lnPaisCanales_" + item.UsuarioLocalidadId}>
                                        <div className="primaryMenu canalesenvio" title="Canales de envio"
                                             onClick={() => closeMenu("lnPaisCanales_" + item.UsuarioLocalidadId)}>
                                            <span>
                                                <img src={ic_send} alt="icon" className="flagIcon"></img>
                                            </span> 
                                            Canales de envio
                                        </div>
                                        <ul>      
                                            <SideBarCountryLi  link={"/canales-envio/registro/" + item.LocalidadId} icon={ic_bell} title={"Notificaciones"} />                                      
                                            {hasPermissions([ROLE.AdministradorSistema, ROLE.CanalEnvio]) &&
                                                <SideBarCountryLi  link={"/canales-envio/plantilla/" + item.LocalidadId} icon={ic_settings} title={"Plantillas"} />
                                            }
                                            {hasPermissions([ROLE.AdministradorSistema, ROLE.CanalEnvio]) && 
                                                <SideBarCountryLi  link={"/canales-envio/parametrizacion/" + item.LocalidadId} icon={ic_feed} title={"Reintentos"} />
                                            }
                                        </ul>
                                    </li>
                                    : ""
                                }
                                {hasPermissions([]) && 
                                    <SideBarCountryLi  link={"/sentimientos/" + item.LocalidadId} icon={ic_dashboard} title={"Análisis de sentimientos"} />
                                }
                            </ul>
                        </li>
                    </ul>
                )
            })
            }
        </>
    }

    return (
        <>
            <div className={["contentPageLeft", props.styleMenu !== undefined ? " sidebarMenuMin" : ""].join(' ')}>
                {sideBar && renderSideBarItems(sideBar)}
            </div>
        </>
    );
};

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
    styleMenu: PropTypes.any
};

export default Navbar;
