import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { EstiloTexto } from "../../../interfaces/estilo";

const HerramientasPantallaInicio = (props: any) => {
    const [loadInfo, setLoadInfo] = useState<boolean>(true);
    let [itemStyle, setItemStyle] = useState<EstiloTexto>({} as EstiloTexto);
    const [backgroudColorInicio, setBackgroudColorInicio] = useState<string>("");
    const [colorInicioInvitado, setColorInicioInvitado] = useState<string>("");
    const [colorInicioLogeado, setColorInicioLogeado] = useState<string>("");
    const [textAlignInicioInvitado, setTextAlignInicioInvitadoInvitado] = useState<string>("");
    const [textDecorationInicioInvitado, setTextDecorationInicioInvitado] = useState<string>("");
    const [textWeightInicioInvitado, setTextWeightInicioInvitado] = useState<string>("");
    const [textAlignInicioLogeado, setTextAlignInicioLogeado] = useState<string>("");
    const [textDecorationInicioLogeado, setTextDecorationInicioLogeado] = useState<string>("");
    const [textWeightInicioLogeado, setTextWeightInicioLogeado] = useState<string>("");


    const defaultTypeStyle = "General";
    const typeStyleList = [
        "General",
        "Explorer",
        "Invitado"
    ];
    const [typeStyle, setTypeStyle] = React.useState<string>(defaultTypeStyle)
    const handleChangeTypeStyle = (event: DropDownListChangeEvent) => {
        if (event) {
            setTypeStyle(event.value)
        }
    }
    useEffect(() => {
        if (!loadInfo) return

        setLoadInfo(false)

        let styleTmp: EstiloTexto = (JSON.parse(props.estilo[0]["Cuerpo"]) as EstiloTexto)
        itemStyle = styleTmp
        setItemStyle(styleTmp)

        setStyle("BIN", styleTmp.backgroudColorInicio !== undefined ? styleTmp.backgroudColorInicio : "#ffffff", true)
        setStyle("CTI", styleTmp.colorInicioInvitado, true)
        setStyle("CTL", styleTmp.colorInicioLogeado, true)
        setStyle("TAI", styleTmp.textAlignInicioInvitado, true)
        setStyle("TAL", styleTmp.textAlignInicioLogeado, true)
        setStyle("TDI", styleTmp.textDecorationInicioInvitado !== undefined ? styleTmp.textDecorationInicioInvitado : "", true)
        setStyle("TDL", styleTmp.textDecorationInicioLogeado !== undefined ? styleTmp.textDecorationInicioLogeado : "", true)
        setStyle("TWI", styleTmp.textWeightInicioInvitado !== undefined ? styleTmp.textWeightInicioInvitado : "", true)
        setStyle("TWL", styleTmp.textWeightInicioLogeado !== undefined ? styleTmp.textWeightInicioLogeado : "", true)

    })
    const setStyle = (tipo: string, style: string, fuerzaUp: boolean = false) => {
        console.log("itemStyle",itemStyle)
        switch (tipo) {
            case "BIN":
                itemStyle.backgroudColorInicio = style
                setBackgroudColorInicio(style)
                break
            case "CTI":
                itemStyle.colorInicioInvitado = style
                setColorInicioInvitado(style)
                break
            case "CTL":
                itemStyle.colorInicioLogeado = style
                setColorInicioLogeado(style)
                break;
            case "TAI":
                itemStyle.textAlignInicioInvitado = style
                setTextAlignInicioInvitadoInvitado(style)
                break
            case "TAL":
                itemStyle.textAlignInicioLogeado = style
                setTextAlignInicioLogeado(style)
                break;
            case "TDI":
                itemStyle.textDecorationInicioInvitado = itemStyle.textDecorationInicioInvitado === "" || fuerzaUp ? style : ""
                style = itemStyle.textDecorationInicioInvitado
                setTextDecorationInicioInvitado(itemStyle.textDecorationInicioInvitado)
                break;
            case "TDL":
                itemStyle.textDecorationInicioLogeado = itemStyle.textDecorationInicioLogeado === "" || fuerzaUp ? style : ""
                style = itemStyle.textDecorationInicioLogeado
                setTextDecorationInicioLogeado(itemStyle.textDecorationInicioLogeado)
                break;
            case "TWI":
                itemStyle.textWeightInicioInvitado = itemStyle.textWeightInicioInvitado === "" || fuerzaUp ? style : ""
                style = itemStyle.textWeightInicioInvitado
                setTextWeightInicioInvitado(itemStyle.textWeightInicioInvitado)
                break;
            case "TWL":
                itemStyle.textWeightInicioLogeado = itemStyle.textWeightInicioLogeado === "" || fuerzaUp ? style : ""
                style = itemStyle.textWeightInicioLogeado
                setTextWeightInicioLogeado(itemStyle.textWeightInicioLogeado)
                break;
        }
        props.setStyleProperty(tipo, style)
    }

    return (
        <div className="herramientasTema herramientas-tema-one">
            <div className="scrollHerramientas">
                <DropDownList
                    className="selector"
                    data={typeStyleList}
                    defaultValue={typeStyle}
                    onChange={handleChangeTypeStyle}
                />
                <div className="scrollHerramientas">
                    {typeStyle === "General" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="color-header-one" className="activator"/>
                                <h1><label htmlFor="color-header-one">General</label></h1>
                                <div className="p">
                                    <p className="titleSection">Color de fondo:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker" value={backgroudColorInicio}
                                                   onChange={(e: any) => setStyle("BIN", e.target.value)}/>
                                            {backgroudColorInicio}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {typeStyle === "Invitado" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="color-log-one" className="activator"/>
                                <h1><label htmlFor="color-log-one">Invitado</label></h1>
                                <div className="p">
                                    <p className="titleSection">Color de texto:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker"
                                                   value={colorInicioInvitado}
                                                   onChange={(e: any) => setStyle("CTI", e.target.value)}/>
                                            {colorInicioInvitado}
                                        </span>
                                    </p>
                                    <p className="titleSection">Herramientas para orientación:</p>
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={textAlignInicioInvitado === 'left' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAI", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={textAlignInicioInvitado === 'center' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAI", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={textAlignInicioInvitado === 'right' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAI", 'right')}/>
                                    </p>
                                    <p>
                                        <Button iconClass="k-icon k-i-bold"
                                                className={textWeightInicioInvitado === 'bold' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TWI", 'bold')}/>
                                        <Button iconClass="k-icon k-i-underline"
                                                className={textDecorationInicioInvitado === 'underline' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TDI", 'underline')}/>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                    {typeStyle === "Explorer" && (
                        <>
                            <div className="c">
                                <input type="checkbox" id="color-inv-one" className="activator"/>
                                <h1><label htmlFor="color-inv-one">Explorer</label></h1>
                                <div className="p">
                                    <p className="titleSection">Color de texto:</p>
                                    <p>
                                        <span className="relative-pos">
                                            <input type="color" id="colorpicker"
                                                   value={colorInicioLogeado}
                                                   onChange={(e: any) => setStyle("CTL", e.target.value)}/>
                                            {colorInicioLogeado}
                                        </span>
                                    </p>
                                    <p className="titleSection">Herramientas para orientación:</p>
                                    <p>
                                        <Button iconClass="k-icon k-i-align-left"
                                                className={textAlignInicioLogeado === 'left' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAL", 'left')}/>
                                        <Button iconClass="k-icon k-i-align-center"
                                                className={textAlignInicioLogeado === 'center' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAL", 'center')}/>
                                        <Button iconClass="k-icon k-i-align-right"
                                                className={textAlignInicioLogeado === 'right' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TAL", 'right')}/>
                                    </p>
                                    <p>
                                        <Button iconClass="k-icon k-i-bold"
                                                className={textWeightInicioLogeado === 'bold' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TWL", 'bold')}/>
                                        <Button iconClass="k-icon k-i-underline"
                                                className={textDecorationInicioLogeado === 'underline' ? 'k-selected' : ''}
                                                onClick={(_e: any) => setStyle("TDL", 'underline')}/>
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
export default HerramientasPantallaInicio
