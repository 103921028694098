import { useEffect, useState } from "react";
import { CreatorBase } from "survey-creator-core";
import { localization, SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import '../../../assets/css/Smartsurvey.css';
import { SetStyleCustom, SetStyleKeyValue } from "../../../components/Common/Survey/Utils";
import { DEFAULT_LANG } from "../../../constants/default";
import {
    addEventPag,
    doOnCurrentPageChanged,
    handleChangePage,
    obtenerEncuestaFinal,
    setStyleByCondtions,
    updateStyleByChange
} from "../../../helpers/smartSurvey";
import { EstiloTexto } from "../../../interfaces/estilo";

let lastMovilSelected = "desktop"
let lastOrientationSelected = "abc"

export const ThemePreview = (props: any) => {
    const [itemStyle, setItemStyle] = useState<EstiloTexto>({} as EstiloTexto);
    const options = {
        showDefaultLanguageInTestSurveyTab: false,
        showTranslationTab: false,
        showLogicTab: false,
        showDesignerTab: false,
        showTestSurveyTab: true,
        showJSONEditorTab: false,
        haveCommercialLicense: true,
        showEmbeddedSurveyTab: false,
        currentLocale: "es",
        localization: "es",
        showTitlesInExpressions: true
    };
    const [loading, setLoading] = useState<boolean>(true);
    const [textoInicioLogeado, setTextoInicioLogeado] = useState<string>("");

    useEffect(() => {
        SetStyleKeyValue("--display-title", "")

        if (!loading) return
        setLoading(false)

        let styleTmp = JSON.parse(props.estilo[0]["Cuerpo"])
        setItemStyle(styleTmp)

        let textoLogeado: string = styleTmp.textoInicioLogeado !== undefined ? styleTmp.textoInicioLogeado : ""
        setTextoInicioLogeado(textoLogeado)

        SetStyleCustom(styleTmp)

        creator.JSON = JSON.parse(pageIniEnd(props.surveyData !== null ? props.surveyData : '{}', textoLogeado))

    })

    const pageIniEnd = (data: any, txtInicioLogeado: string) => {
        if (txtInicioLogeado !== "") {
            SetStyleKeyValue("--display-title", "none")

            let formulario = JSON.parse(data)
            let pages = formulario["pages"]
            let page = {
                "name": "Pagina0", "elements": [{
                    "type": "html",
                    "html": "<div class='pantall-inicio one-theme-backgroud'><div class='one-text-log'>" + txtInicioLogeado + "</div></div>"
                }]
            }
            pages.unshift(page)
            formulario["pages"] = pages

            let htmlFinEncuesta = "<div class='fin_encuesta three-theme-backgroud three-text-inv'><span>" + obtenerEncuestaFinal(itemStyle, null,DEFAULT_LANG) + "</span><br/><button type='button' onclick='{survey.clear(true, true)}'>Restart</button></div>"

            formulario["progressBarType"] = "buttons"
            formulario["showProgressBar"] = "top"
            formulario["firstPageIsStarted"] = true
            formulario["startSurveyText"] = "Inicio"
            formulario["completedHtml"] = htmlFinEncuesta
            return JSON.stringify(formulario)
        }
        return data
    }
    const onComplete = () => {
        SetStyleKeyValue("--display-title", "none")
    }
    const onStarted = () => {
        let surveyInternalNext = (document.getElementById('survey_page_next') as HTMLElement)
        if (surveyInternalNext) {
            surveyInternalNext.style.display = "inline";
        }
        window["count"] = window["survey"].pageNumber - 1
        setTimeout(handleChangePage, 1, -1, window["count"])
        SetStyleKeyValue("--display-title", "")
    }

    let creator = new SurveyCreator(options);
    const similatorGr = creator.getPlugin("test").model.simulator;
    setStyleByCondtions(similatorGr.device, similatorGr.landscape, lastMovilSelected, lastOrientationSelected)
    updateStyleByChange()

    similatorGr.registerFunctionOnPropertyValueChanged("landscape", (_newValue: string) => {
        updateStateCondition(similatorGr)
        updateStyleByChange()
    });
    similatorGr.registerFunctionOnPropertyValueChanged("device", (_newValue: string) => {
        updateStateCondition(similatorGr)
        updateStyleByChange()
    });
    window['survey'] = creator.survey
    addEventPag("es")

    creator.onSurveyInstanceCreated.add((_sender: CreatorBase, _options: any) => {
        let stylePreview = (document.getElementsByClassName('svc-creator')[0] as HTMLElement)
        let styleSurveyBase = (document.getElementsByClassName('container-base-survey')[0] as HTMLElement)

        if (_options.reason === "test") {
            if (stylePreview && styleSurveyBase) {
                styleSurveyBase.classList.add("styleSurvey")
                stylePreview.classList.add("styleNav3")
                stylePreview.classList.add("client-survey")
            }


            _options.survey.onStarted.add(onStarted)
            _options.survey.onComplete.add(onComplete)
            _options.survey.onCurrentPageChanged.add(doOnCurrentPageChanged)

            const similator = creator.getPlugin("test").model.simulator;
            setStyleByCondtions(similator.device, similator.landscape, lastMovilSelected, lastOrientationSelected)
            updateStyleByChange()

            similator.registerFunctionOnPropertyValueChanged("landscape", (_newValue: string) => {
                updateStateCondition(similator)
                updateStyleByChange()
            });
            similator.registerFunctionOnPropertyValueChanged("device", (_newValue: string) => {
                updateStateCondition(similator)
                updateStyleByChange()
            });

            window['survey'] = _options.survey
            addEventPag("es")
        } else {
            if (stylePreview && styleSurveyBase) {
                stylePreview.classList.remove("styleNav3")
                stylePreview.classList.remove("client-survey")
                styleSurveyBase.classList.remove("styleSurvey")
            }
        }
    })
    const updateStateCondition = (similator: any) => {
        let result = setStyleByCondtions(similator.device, similator.landscape, lastMovilSelected, lastOrientationSelected)
        let res: string[] = result.split('-')

        lastMovilSelected = res[0]
        lastOrientationSelected = res[1]
    }


    useEffect(() => {
        SetStyleKeyValue("--display-title", "")
        localization.currentLocale = "es"
        if (textoInicioLogeado !== "")
            creator.JSON = JSON.parse(pageIniEnd(props.surveyData !== null ? props.surveyData : '{}', textoInicioLogeado))
    })

    return (
        <div className="page-content custom-preview styleSurvey">
            <div className="titleTheme1">
                <div className="btn_back" onClick={() => {
                    props.closePreview()
                }}>Galería de temas
                </div>
                / Previsualización
            </div>
            <div className="contentPageRight contentCenter-preview styleNav3 client-survey min-heigth-survey">
                <SurveyCreatorComponent creator={creator}/>
            </div>
            <div className="contentSidebarRight contentRight-preview">
                <div className="herramientasTema">
                </div>
            </div>
        </div>
    )
}
