import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from "react";
import { postPublic as post } from './helpers/api_helper';
import { apiShortToken } from './constants/api';
 

const ShortTokenRedirect = () => {
    const navigate = useNavigate();
    const {token} = useParams();
    
    useEffect(() => {
        post(apiShortToken, {Code: token}).then((res) => {
            if (res !== ""){
                navigate(`/encuesta/${res}`);
            }else{
                navigate(`/prohibido`);
            }
        }).catch((error) => {
            navigate(`/prohibido`);
        })
    }, []);

    return <></>;
};

export default ShortTokenRedirect;
