import axios from "axios";
import { authHeader } from "./authHeader";

const API_URL = process.env.REACT_APP_BACKEND;
let enableToken: boolean = true
const axiosApi = axios.create({
    baseURL: API_URL
})

// Set the AUTH token for any request
axiosApi.interceptors.request.use(async config => {
    if (enableToken)
        config.headers = {Authorization: `Bearer ${(await authHeader()).accessToken}`}
    enableToken = true
    return config;
});


export async function get(url: string) {
    return axiosApi.get(url).then(response => response.data)
}

export async function post(url: string, data: Object) {
    return axiosApi.post(url, {...data}).then(response => response.data)
}

export async function postPublic(url: string, data: Object) {
    enableToken = false
    return axiosApi.post(url, {...data}).then(response => response.data)
}

export async function postSalidas(url: string) {
    return axiosApi.post(url).then(response => response.data)
}

export async function put(url: string, data: Object) {
    return axiosApi.put(url, {...data}).then(response => response.data)
}

export async function del(url: string) {
    return axiosApi.delete(url).then(response => response.data)
}
