import { Navigate, useLocation } from 'react-router-dom';
import { msalInstance } from "./index";
import { ROLE } from "./interfaces/roles";


const useAuth = () => {
    let user;

    const isAuth = !!msalInstance.getActiveAccount();
    if (isAuth) {
        user = {loggedIn: true};
    } else {
        user = {loggedIn: false};
    }

    return user && user.loggedIn
};
const PrivateRoute = ({children, roles}: { children: JSX.Element; roles: Array<ROLE>; }) => {
    let location = useLocation();
    const user = msalInstance.getActiveAccount();
    let userHasRequiredRole = false
    const isAuthenticated = useAuth();
    try {
        // @ts-ignore
        let intersection = roles.filter(role => user.idTokenClaims['roles'].includes(role));
        if (intersection.length > 0) {
            userHasRequiredRole = true;
        }


        if (!isAuthenticated) {
            return <Navigate to="/login" state={{from: location}}/>;
        }

        if (isAuthenticated && !userHasRequiredRole) {
            return <Navigate to="/prohibido" state={{from: location}}/>;
        }

        return children;
    } catch (e) {
        return <Navigate to="/login" state={{from: location}}/>;
    }


};

export default PrivateRoute;
