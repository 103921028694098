import { normalizeTitle } from './utilsGrids';
import { ColumnaFormulario } from "../interfaces/formularios";

export const getPregunstasFormulario = (formulario: string) => {
    let colunasTmp: ColumnaFormulario[] = [] as ColumnaFormulario[];
    let detFor = JSON.parse(formulario);
    detFor.pages.forEach((page: any) => {
        if (page?.elements) {
            page.elements.forEach((title: any) => {
                let col: ColumnaFormulario = {} as ColumnaFormulario;
                col.title = title.title ? normalizeTitle(title.title) : title.name;
                col.field = title.name;
                col.tipo = title.type;
                col.show = true;
                colunasTmp.push(col); 
            });
        }
    });

    return colunasTmp
}
