// Base
export const apiSelectionListAll = "ListaSeleccion?$orderby=Nombre";
export const apiSelectionListByLocation = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'pais'";
export const apiSelectionListByPosition = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'ticket.cargo'";
// Url Formulario
export const apiSelectionListByFolder = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'formulario.carpeta'";
export const apiListForm = "Formulario?$count=true&$expand=Producto,Idioma,Estado($Select=EstadoId,Nombre),Carpeta($Select=ListaSeleccionId,Nombre),FormularioEnvio($count=true;$filter=Estado/Nombre eq 'Finalizado' and Eliminado eq false;$top=0),FormularioRespuesta($count=true;$top=0)&";
export const apiCreateForm = "Formulario";
export const apiDetailForm = "Formulario(:id)?$expand=FormularioRegla";
export const apiUpdateForm = "Formulario(:id)";
export const apiDeleteForm = "Formulario(:id)";
export const apiResetForm = "Formulario/Reset";
export const apiAddFolderForm = "Formulario/Carpeta";
export const apiChangeStateForm = "Formulario/Estado/:action/:id";
export const apiDuplicateForm = "Formulario/Duplicar/(:id)"

// Urls Selection List
export const apiListSelectionList = "ListaSeleccion?$orderby=Nombre&$count=true&";
export const apiCreateSelectionList = "ListaSeleccion";
export const apiUpdateSelectionList = "ListaSeleccion(:id)";
export const apiDeleteSelectionList = "ListaSeleccion(:id)";
export const apiSelectionListById = "ListaSeleccion?id=";

// Url Product
export const apiListProduct = "Producto?$count=true&$expand=Localidad($Select=ListaSeleccionId,Nombre)&";
export const apiCreateProduct = "Producto";
export const apiUpdateProduct = "Producto(:id)";
export const apiDeleteProduct = "Producto(:id)";
export const apiProductById = "Producto?id=";


// Url locality user
export const apiListLocalityUser = "UsuarioLocalidad?$count=true&$expand=Localidad,Cargo&";
export const apiCreateLocalityUser = "UsuarioLocalidad";
export const apiUpdateLocalityUser = "UsuarioLocalidad(:id)";
export const apiDeleteLocalityUser = "UsuarioLocalidad(:id)";
export const apiLocalityUserById = "UsuarioLocalidad?id="

// Url Style

export const apiListStyle = "Estilo?$count=true&$expand=Localidad($Select=Nombre,Valor)&"
export const apiCreateStyle = "Estilo"
export const apiUpdateStyle = "Estilo(:id)"
export const apiDeleteStyle = "Estilo(:id)"
export const apiStyleById = "Estilo?id=";

// Url Ticket Responsable


export const apiFormularios = "Formularios?$expand=FormularioEnvio,FormularioRespuesta,Estilo,Estado,Producto";
export const apiListaSeleccionById = "ListaSeleccion?id=";
export const apiUsuarioLocalidad = "UsuarioLocalidad";
export const apiListaSeleccionLocalidad = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'pais'";
export const apiUsuarioLocalidadByUsuario = "UsuarioLocalidad?$expand=Cargo($Select=Nombre,Valor),Localidad($Select=Nombre,Valor)&$orderby=Localidad/Orden&$filter=Usuario eq ':user'";
export const apiFormulario = "Formulario?$count=true&$expand=Producto,Estado($Select=EstadoId,Nombre),Carpeta($Select=ListaSeleccionId,Nombre),FormularioEnvio($count=true;$filter=Estado/Nombre eq 'Finalizado';$top=0),FormularioRespuesta($count=true;$top=0)&";
export const apiProductoList = "Producto?$orderby=Nombre";
export const apiFormularioCrear = "Formulario";
export const apiFormularioDetalle = "Formulario(:id)?$expand=FormularioRegla";
export const apiFormularioUpdate = "Formulario(:id)";
export const apiFormularioUpdateDetalle = "Formulario/UpdateDetalle";
export const apiDetalleFormularioList = "FormularioEnvio?$count=true&$expand=Estado($Select=EstadoId,Nombre),Formulario($Select=Nombre,DetalleFormulario),FormularioRespuesta($Select=FormularioRespuestaId,DetalleRespuesta,FechaInicio,FechaFin,Revisado)&$filter=FormularioId eq :formId&";
export const apiListaSeleccionCapeta = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'formulario.carpeta'";
export const apiEncuestaBusiness = "EncuestaBusiness";
export const apiProductoById = "Producto?id=";
export const apiEstilo = "Estilo";
export const apiEstiloById = "Estilo?id=";
export const apiTemaCount = "Estilo?$expand=Formulario($count=true)";
export const apiReglaCrear = "FormularioRegla";

export const apiListaSeleccionTipo = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'ticket.tipo'&";
export const apiListaSeleccionServicio = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'ticket.servicio'";
export const apiListaSeleccionCategoria = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'ticket.categoria'";
export const apiListaSeleccionSubcategoria = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'ticket.subcategoria'";
export const apiListaSeleccionResponsable = "UsuarioLocalidad?$orderby=Usuario&$filter=LocalidadId";
export const apiListaSeleccionTicket = "ListaSeleccion?$orderby=Nombre&$filter=contains(Ambito,'ticket')&";

//API gestión de tickets
export const apiTicket = "Ticket?$count=true&$expand=Tipo($Select=ListaSeleccionId,Nombre),Categoria($Select=ListaSeleccionId,Nombre),Subcategoria($Select=ListaSeleccionId,Nombre),Estado($Select=EstadoId,Nombre),Producto($Select=Nombre),Servicio($Select=ListaSeleccionId,Nombre),FormularioRespuesta($Select=FechaInicio,FormularioId,Formulario,FormularioRespuestaId,FormularioEnvioId;$expand=Formulario($Select=FechaPublicacion)),TicketResponsable($Select=TicketResponsableId,UsuarioLocalidadId;$expand=UsuarioLocalidad($Select=Usuario)),TicketObservador($Select=TicketObservadorId,UsuarioLocalidadId;$expand=UsuarioLocalidad($Select=Usuario)),TicketDescargo($top=1;$orderby=FechaCreacion desc;$Select=TicketDescargoId,Respuesta)&";

export const apiTicketMisTickets = "Ticket?$count=true&$expand=Tipo($Select=ListaSeleccionId,Nombre),Categoria($Select=ListaSeleccionId,Nombre),Subcategoria($Select=ListaSeleccionId,Nombre),Estado($Select=EstadoId,Nombre),Producto($Select=Nombre),FormularioRespuesta($Select=FormularioId,Formulario;$expand=Formulario($Select=FechaPublicacion)),TicketResponsable($Select=TicketResponsableId, UsuarioLocalidadId;$expand=UsuarioLocalidad($Select=Usuario))&$filter=TicketResponsable/any(a%3aa/UsuarioLocalidadId eq :UsuarioLocalidadId&";
export const apiTicketPorId = "Ticket(:id)";
export const apiTicketDescargo = "TicketDescargo?$count=true&$expand=Ticket($Select=NumeroTicket,Comentario,ComentarioTraducido),Estado($Select=EstadoId,Nombre)&";
export const apiTicketDescargoPorTicketId = "TicketDescargo/Ticket(:id)?$orderby=fechaCreacion desc";
export const apiTicketDescargoPorId = "TicketDescargo?id="
export const apiFormularioProducto = "Formulario/(:id)/Producto?$Select=FormularioId,ProductoId,Nombre,LocalidadId&$expand=Producto($Select=Nombre)";
export const apiListaSeleccionArbol = "ListaSeleccion?$filter=contains(Ambito,'ticket') and ListaSeleccionPadreId eq null&";
export const apiListaEstadosTicket = "Estado?$orderby=Nombre&$filter=Ambito eq 'Ticket'&";
export const apiFormularioRespuestaMarca = "FormularioRespuestaMarca";
export const apiFormularioRespuestaMarcaPorId = "FormularioRespuestaMarca(:id)";
export const apiFormularioRespuestaMarcaPorFormularioRespuestaId = "FormularioRespuestaMarca/FormularioRespuestaId(:id)";
export const apiListaSeleccion = "ListaSeleccion";
export const apiTicketResponsableCategoriaSubcategoria = "ResponsableCategoriaSubcategoria?$filter=CategoriaId eq :categoriaId and SubcategoriaId eq :subcategoriaId&$expand=UsuarioLocalidad($Select=UsuarioLocalidadId,Usuario)";
export const apiListaSeleccionParaTickets = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'pais' or Ambito eq 'ticket.tipo' or Ambito eq 'ticket.servicio' or Ambito eq 'ticket.categoria' or Ambito eq 'ticket.subcategoria'  or Ambito eq 'ticket.cargo' or Ambito eq 'ticket.grupo'&";
export const apiArbolResponsables = "ArbolResponsables";
export const apiListaSeleccionSubcategoriaCategoria = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'ticket.subcategoria' and ListaSeleccionPadreId eq :categoriaId";
export const apiCategoriaServicioCategoria = "CategoriaServicio?$filter=CategoriaId eq :categoriaId&$expand=Servicio($Select=ListaSeleccionId,Nombre)";
export const apiTicketPorFomularioRespuestaId = "Ticket?$filter=FormularioRespuestaId eq :formularioRespuestaId";
export const apiListaSeleccionServicioCategoria = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'ticket.servicio' and ListaSeleccionPadreId eq :categoriaId";
export const apiCategoriaServicio = "CategoriaServicio?$count=true&$expand=Categoria($Select=ListaSeleccionId, Nombre),Servicio($Select=ListaSeleccionId,Nombre)&";

export const apiResponsableCategoriaSubcategoria = "ResponsableCategoriaSubcategoria?$count=true&$expand=UsuarioLocalidad($Select=UsuarioLocalidadId,Usuario;$filter=LocalidadId%20eq%20:LocalidadId),Producto($Select=ProductoId, Nombre),Categoria($Select=ListaSeleccionId, Nombre),Subcategoria($Select=ListaSeleccionId, Nombre)&";
export const apiResponsableCategoriaSubcategoriaPorProductoIdCategoriaIdSubcategoriaId = "ResponsableCategoriaSubcategoria/Producto(:productoId)/Categoria(:categoriaId)/Subcategoria(:subcategoriaId)";
export const apiFormularioEnvio = "FormularioEnvio?$count=true&$filter=FormularioId eq :formId&$expand=FormularioRespuesta($filter=FormularioEnvioId eq :formEnvioId)";

export const apiFormularioListPorLocalidadId = "Formulario?$orderby=Nombre&$count=true&$Select=FormularioId,Nombre&$filter=LocalidadId%20eq%20:LocalidadId&";
export const apiProductoListPorLocalidadId = "Producto??$orderby=Nombre&$filter=LocalidadId%20eq%20:LocalidadId&$orderby=Nombre asc";
export const apiCategoriaServicioPorCategoriaId = "CategoriaServicio?$filter=CategoriaId eq :categoriaId&$expand=Servicio($Select=ListaSeleccionId,Nombre)";

export const apiTicketEstadoPorTicketId = "TicketEstado?$expand=Ticket($Select=TicketId, NumeroTicket),Estado($Select=EstadoId, Nombre)&$filter=TicketId eq :TicketId&";

export const apiListFormDetail = "FormularioEnvio?$count=true&$expand=Estado($Select=EstadoId,Nombre),Formulario($Select=Nombre,DetalleFormulario,ProductoId),FormularioRespuesta($Select=FormularioRespuestaId,DetalleRespuesta,FechaInicio,FechaFin,Revisado,SalidaId,CodigoBooking;$expand=Salida,Ticket($filter=Eliminado eq false;$Select=Comentario),FormularioIndicador($filter=IndicadorCodigo eq 'NPS';$Select=IndicadorValor))&";
export const apiFormularioRespuestaMarcaRevision = "FormularioRespuestaMarca/Revision";

export const apiTicketResponsablePorTicketId = "TicketResponsable?$filter=TicketId eq :ticketId&$expand=UsuarioLocalidad($Select=UsuarioLocalidadId,Usuario)";
export const apiTicketObservadorPorTicketId = "TicketObservador?$filter=TicketId eq :ticketId&$expand=UsuarioLocalidad($Select=UsuarioLocalidadId,Usuario)";
export const apiFormularioRespuestaMarcaJustificacionPorFormularioRespuestaMarcaId = "FormularioRespuestaMarcaJustificacion?$filter=FormularioRespuestaMarcaId eq :formularioRespuestaMarcaId";
export const apiObservacionesFormularioRespuestaPorPregunta = "TicketDescargo?$count=true&$expand=Ticket($select=TicketId, FormularioRespuestaId, Comentario)&$filter=Ticket/FormularioRespuestaId eq :formularioRespuestaId and Ticket/Comentario eq ':comentario'";
export const apiTicketTicketDescargosPorFomularioRespuestaIdComentario = "Ticket?$count=true&$expand=TicketDescargo($Select=TicketDescargoId, FechaCreacion, Respuesta;$orderby=FechaCreacion desc)&$orderby=FechaCreacion desc&$filter=FormularioRespuestaId eq :formularioRespuestaId and Comentario eq ':comentario'";


export const apiProductoCategoriaSubcategoriaResponsablesServicios = "ProductoCategoriaSubcategoriaResponsablesServicios?";


export const apiBusinessSurvey = "EncuestaBusiness";
export const apiListTicketByResponsible = "TicketByResponsable?$count=true&$expand=Tipo($Select=ListaSeleccionId,Nombre),Categoria($Select=ListaSeleccionId,Nombre),Subcategoria($Select=ListaSeleccionId,Nombre),Estado($Select=EstadoId,Nombre),Producto($Select=Nombre),FormularioRespuesta($Select=FormularioId,Formulario,FormularioRespuestaId,FormularioEnvioId;$expand=Formulario($Select=FechaPublicacion)),TicketResponsable($Select=TicketResponsableId,UsuarioLocalidadId;$expand=UsuarioLocalidad($Select=Usuario))&";

export const apiTranslate = "Translate"

// New Sprint
// Url
export const apiSelectionListByIdioma = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'idioma'";
export const apiSelectionListByShared = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'formulario.tipocompartir'";
export const apiSelectionListByStateSend = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'canalenvio.estado'";
export const apiListProductAll = "Producto";
export const apiListProductByLocalidad = "Producto?$orderby=Nombre&$filter=LocalidadId eq ";


// Url Canal Envío Parametrizacion
export const apiListCanalEnvioParameter = "CanalEnvioParametrizacion?$count=true&$expand=Producto&";
export const apiCreateCanalEnvioParameter = "CanalEnvioParametrizacion";
export const apiUpdateCanalEnvioParameter = "CanalEnvioParametrizacion(:id)";
export const apiDeleteCanalEnvioParameter = "CanalEnvioParametrizacion(:id)";
export const apiCanalEnvioParameterById = "CanalEnvioParametrizacion?id="

// Url Canal Envío Template
export const apiListCanalEnvioTemplate = "CanalEnvioTemplate?$count=true&$expand=Producto,Idioma&";
export const apiCreateCanalEnvioTemplate = "CanalEnvioTemplate";
export const apiUpdateCanalEnvioTemplate = "CanalEnvioTemplate(:id)";
export const apiDeleteCanalEnvioTemplate = "CanalEnvioTemplate(:id)";
export const apiCanalEnvioTemplateById = "CanalEnvioTemplate?id="

// Url Canal Envío Registro
export const apiListCanalEnvioRegistro = "Reserva?$count=true&$expand=Pasajero($expand=Idioma),Producto($expand=CanalEnvioTemplate),Estado,CanalEnvioRegistroTipo($expand=Estado)&";
export const apiCreateCanalEnvioRegistro = "CanalEnvioBusiness/Add";
export const apiUpdateCanalEnvioRegistro = "CanalEnvioBusiness/Update";
export const apiCanalEnvioRegistroById = "CanalEnvioRegistro?id="
export const apiMassiveCreateCanalEnvioRegistro = "CanalEnvioBusiness/Massive"
export const apiUpdateStateCanalEnvio = "CanalEnvioBusiness/State"
export const apiTypeCanalEnvioRegistro = "CanalEnvioBusiness/TipoEnvio"
export const apiListCanalEnvioRegistroTipo = "CanalEnvioRegistroTipo?$count=true&$expand=Estado,CanalEnvioRegistroEnvio($expand=Estado,CanalEnvioRegistroEstado($expand=Estado))&$orderby=fechaCreacion desc&$filter=ReservaId eq :ReservaId";
export const apiMassiveCreateEncuestas = "CanalEnvioBusiness/Encuesta/Massive"
export const apiShortToken = "ShortToken" 

// Dashboard
export const apiDashboard = "EncuestaBusiness/Dashboard"

export const apiDeleteArbolResponsable = "Delete/ArbolResponsable";

export const apiFormListByStateActive = "Formulario?$count=true&$expand=Producto($Select=ProductoId,Nombre),Idioma($Select=ListaSeleccionId,Nombre),Estado($Select=EstadoId,Nombre)&$orderby=fechaCreacion desc&$filter=Estado/Nombre eq 'Activo' and LocalidadId eq :LocalidadId and Eliminado eq false & "

// Service Notify
export const apiSendMessage = "SendMessage";
export const apiSendWhatsApp = "SendWhatssap";
export const apiSendEmail = "SendEmail";

// New Urls
export const apiSelectionListStateByValor = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'canalenvio.estado' and Valor eq ':Valor'";
export const apiStateListByAmbitoNombre = "Estado?$orderby=Nombre&$filter=Ambito eq ':Ambito' and Nombre eq ':Nombre'";
export const apiEstiloByValor = "Estilo?$filter=Nombre eq ':Valor'";
export const apiSelectionListByAmbitoValor = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq ':Ambito' and Valor eq ':Valor'";
export const apiSelectionListByAmbito = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq ':Ambito'";

export const apiListProductByProductId = "Producto?$filter=Eliminado eq false and ProductoId eq ";
export const apiListFormularioByProductId = "Formulario?$filter=Estado/Nombre eq 'Activo' and ProductoId eq :ProductoId and LocalidadId eq :LocalidadId and Eliminado eq false";
export const apiListTemplateByProductId = "CanalEnvioTemplate?$filter=ProductoId eq :ProductoId and LocalidadId eq :LocalidadId and CanalEnvio eq ':CanalEnvio' and Eliminado eq false";
export const apiSelectionListByGreeting = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'saludo'";

export const apiListChartDetailForm = "FormularioDetalle/Resume"
export const apiSalidaList = "Salida?$orderby=FechaInicio&$filter=ProductoId eq "
export const apiFormularioRespuestaSalida = "FormularioRespuesta/Salida"
export const apiFormularioRespuestaBooking = "FormularioRespuesta/Booking"

export const apiEstadoEncuesta = "Estado?$orderby=Nombre&$filter=Ambito eq 'Formulario.respuesta' and Eliminado eq false";
export const apiTipoEnvioCat = "ListaSeleccion?$orderby=Nombre&$count=true&$filter=Ambito eq 'formulario.tipocompartir' and Eliminado eq false ";

export const apiReprocesarindicadores = "EncuestaBusiness/ReprocesarIndicador";

// Urls text analytis
export const apiListTextAnalytis = "ChatGptanalytics?$count=true&";
export const apiCreateTextAnalytis = "ChatGptanalytics";
export const apiUpdateTextAnalytis = "ChatGptanalytics(:id)";
export const apiDeleteTextAnalytis = "ChatGptanalytics(:id)";
export const apiTextAnalytisById = "ChatGptanalytics?id=";
export const apiListFormByFilter = "Formulario?$Select=FormularioId, Nombre&$orderby=Producto/Nombre&$filter=LocalidadId eq :LocalidadId and Eliminado eq false";
export const apiTextAnalize = "EncuestaBusiness/ChatGptanalytics/Analyze";
export const apiListTextAnalytisTracking = "ChatGptanalyticsTracking?$count=true&$orderby=fechaCreacion desc&$filter=ChatGptanalyticsId eq :ChatGptanalyticsId";


// Urls text analytis config
export const apiListTextAnalytisConfig = "ChatGptconfiguracion?$count=true&";
export const apiCreateTextAnalytisConfig = "ChatGptconfiguracion";
export const apiUpdateTextAnalytisConfig = "ChatGptconfiguracion(:id)";
export const apiDeleteTextAnalytisConfig = "ChatGptconfiguracion(:id)";
export const apiTextAnalytisConfigById = "ChatGptconfiguracion?id=";
export const apiActiveTextAnalytisConfig = "ChatGptconfiguracion/Activa";


// urls Analisis de sentimientos V2
export const apiListTextAnalytisV2 = "AnalisisSentimientoExtendida?$count=true&$expand=FormularioRespuesta($expand=Formulario($expand=Producto))&";
export const apiSeccionAnalisSentimientos = "ListaSeleccion?$orderby=Nombre&$filter=Ambito eq 'analisissentimiento.seccion' and Eliminado eq false";