export enum ROLE {
    AdministradorSistema = 'AdministradorSistema',
    VisorListaSeleccion = 'VisorListaSeleccion',
    VisorFormularioActivo = 'VisorFormularioActivo',
    VisorFormularioEliminado = 'VisorFormularioEliminado',
    VisorUsuarioLocalidad = 'VisorUsuarioLocalidad',
    EditarFormulario = 'EditarFormulario',
    VisorEncuesta = 'VisorEncuesta',
    VisorProducto = 'VisorProducto',
    GestionFeedback = 'GestionFeedback',
    GestionFeedbackResponsable = 'GestionFeedbackResponsable',
    Dashboard = 'Dashboard',
    CanalEnvio = 'CanalEnvio',
    VisorCanalEnvio = 'VisorCanalEnvio',
    DashboardCanales = 'DashboardCanales',
    DashboardBI = 'DashboardBI',
}

