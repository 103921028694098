import { LogLevel } from '@azure/msal-browser'

const REACT_APP_PRODUCTION = process.env.REACT_APP_PRODUCTION
let system = {}
if (!REACT_APP_PRODUCTION) {
    system = {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }

                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                }
            },

        },

    }
}
export const msalConfig = {
    auth: {
        clientId: '4179a6ad-522f-4f41-b1ad-0db005d8e59c',
        authority: 'https://login.microsoftonline.com/2a967794-c4d1-4bc2-8036-1a5201a5d767',
        redirectUri: '/',
        postLogoutRedirectUri: 'https://localhost:3000',
        navigateToLoginRequestUrl: false,
        tokenRefreshUri: window.location.origin + '/refresh',

    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },

    system: system,
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}

export const protectedResources = {
    apiSmartsurvey: {
        todoListEndpoint: 'http://localhost:5000/api/todolist',
        dashboardEndpoint: 'http://localhost:5000/api/dashboard',
        scopes: ['api://787f3b1a-a099-4e5f-b793-084138766497/.default'],
    },
}

export const loginRequest = {
    scopes: [...protectedResources.apiSmartsurvey.scopes],
}


