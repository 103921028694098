import PropTypes from 'prop-types'
import { useState } from "react"
import { Link } from "react-router-dom"

import logo from "../../assets/svg/metropolitan-movil.svg";
// Redux Store
//i18n
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

const Header = (props: any) => {
    const [isSearch, setSearch] = useState(false)    

    function toggleFullscreen() {
        const document: any = window.document;
        const element: any = document.Element;
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    element.ALLOW_KEYBOARD_INPUT
                )
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    const openMenu = () => {
        if ($(".ocultarMenu").length > 0) {
            $(".topMenu").addClass("backgroundActive");
            $('#layout-wrapper').addClass("showLeftControls");
            $('.contentPageLeft').removeClass("ocultarMenu");
        } else {
            $(".topMenu").removeClass("backgroundActive");
            $('#layout-wrapper').removeClass("showLeftControls");
            $('.contentPageLeft').addClass("ocultarMenu");
        }
    }

    return (
        <>
            <header id="page-topbar">
                <div className={"navbar-header"}>
                    <div className="d-flex" onClick={openMenu}>
                        <div className="navbar-brand-box">
                            <Link to="#" className="logo logo-light logo-dark">
                                <span className="logo-sm">
                                <img src={logo} alt="SmarySurvey" height="22"/>
                                </span>
                                <span className="logo-lg">
                                <img src={logo} alt="SmarySurvey" height="19"/>
                                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                            data-toggle="collapse"
                            onClick={() => {
                                props.toggleLeftmenu(!props.leftMenu)
                            }}
                            data-target="#topnav-menu-content"
                        >
                            <i className="fa fa-fw fa-bars"/>
                        </button>
                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button
                                type="button"
                                className="btn header-item noti-icon"
                                id="page-header-search-dropdown"
                                onClick={() => setSearch(!isSearch)}
                            >
                                <i className="mdi mdi-magnify"/>
                            </button>
                            <div
                                className={
                                    isSearch
                                        ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                                        : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                }
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input  
                                                type="text"
                                                className="form-control"
                                                placeholder={"Buscar ..."}
                                                aria-label="Buscar"
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit">
                                                    <i className="mdi mdi-magnify"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* <LanguageDropdown /> */}
                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <button
                                type="button"
                                className="btn header-item noti-icon "
                                onClick={() => {
                                    toggleFullscreen()
                                }}
                                data-toggle="fullscreen"
                            >
                                <i className="bx bx-fullscreen"/>
                            </button>
                        </div>
                        <div>
                        </div>

                        <ProfileMenu/>
                    </div>
                </div>
            </header>

        </>
    )
}

Header.propTypes = {
    leftMenu: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = (state: any) => {
    const {layoutType, showRightSidebar, leftMenu} = state.Layout
    return {layoutType, showRightSidebar, leftMenu}
}

export default Header;
