function init (Survey, $) {
  const iconId = 'icon-barrating'
  Survey.SvgRegistry && Survey.SvgRegistry.registerIconFromSvg(iconId, require('svg-inline-loader?classPrefix!./images/barrating.svg'), '')
  $ = $ || window.$
  var widget = {
    name: 'barrating',
    title: 'Bar rating',
    iconName: iconId,
    widgetIsLoaded: function () {
      return typeof $ == 'function' && !!$.fn.barrating
    },
    defaultJSON: { choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] },
    isFit: function (question) {
      return question.getType() === 'barrating'
    },
    isDefaultRender: true,
    activatedByChanged: function (activatedBy) {
      Survey.JsonObject.metaData.addClass(
        'barrating',
        [
          { name: 'hasOther', visible: false },
          { name: 'otherText', visible: false },
          { name: 'optionsCaption', visible: false },
          { name: 'otherErrorText', visible: false },
          { name: 'storeOthersAsComment', visible: false },
          { name: 'renderAs', visible: false },
          { name: 'select2Config', visible: false },
        ],
        null,
        'dropdown',
      )
      Survey.JsonObject.metaData.addProperty('barrating', {
        name: 'showValues:boolean',
        default: false,
        category: 'general',
      })
      Survey.JsonObject.metaData.addProperty('barrating', {
        name: 'ratingTheme',
        category: 'general',
        default: 'css-estrellas',
        choices: [
          'css-caras',
          'css-estrellas',
          'css-manos',
          'css-numeros',
        ],
      })
      Survey.JsonObject.metaData.addProperty('barrating', {
        name: 'ratingScala',
        category: 'general',
        default: 'Escala10',
        choices: [
          { value: 'Escala5', text: 'Escala de 5' },
          { value: 'Escala10', text: 'Escala de 10' },
        ],
      })
      Survey.JsonObject.metaData.addProperty('barrating', {
        name: 'orientation',
        category: 'general',
        default: 'horizontal',
        choices: [
          { value: 'horizontal', text: 'Horizontal' },
          { value: 'vertical', text: 'Vertical' },
        ],
      })
      Survey.JsonObject.metaData.addProperty('barrating', {
        name: 'colorScale',
        category: 'general',
        default: 'scale-color',
        choices: [
          { value: 'scale-color', text: 'Color' },
          { value: 'scale-gray', text: 'Escala de grises' },
        ],
      })
      Survey.JsonObject.metaData.addProperty('barrating', {
        name: 'textoInicial',
        category: 'general',
        default: 'Inicio',
        isLocalizable: true,
      })
      Survey.JsonObject.metaData.addProperty('barrating', {
        name: 'textoFinalizacion',
        category: 'general',
        default: 'Fin',
        isLocalizable: true,
      })
    },
    afterRender: function (question, el) {
      var $customSelect
      var $questionInput
      var contentContainer = $(el).is('select')
        ? $(el).parent().parent()[0]
        : $(el).parent()[0]
      var renderCustomSelect = function () {
        $customSelect = $('<select class=\'sv-widget-select\'></select>')
        $customSelect.append('<option disabled selected value style="display:none"></option>')
        question.visibleChoices.forEach(function (choice) {
          $customSelect.append(
            '<option value="{0}">{1}</option>'.format(choice.value, choice.text),
          )
        })
        $questionInput = $(contentContainer).find(
          '[id="{0}"]'.format(question.inputId),
        )
        $questionInput.css('display', 'none')
        $questionInput.after($customSelect)
        console.log("abccc:",$customSelect[0].selectedIndex)
        setTimeout(()=>{
          $customSelect[0].selectedIndex = customSelect[0].length - 1
        },250)
      }
      var removeCustomSelect = function () {
        $questionInput.css('display', '')
        $customSelect.barrating('destroy')
        $customSelect.remove()
      }
      var renderBarrating = function () {
        $customSelect.barrating('show', {
          theme: question.ratingTheme,
          initialRating: question.value,
          showValues: question.showValues,
          showSelectedRating: false,
          readonly: question.isReadOnly,
          onSelect: function (value, text) {
            valueChangingByWidget = true
            question.value = value
            valueChangingByWidget = false
          },
        })

      }
      var renderBarratingText = function () {
        let parent = $customSelect.parents()[0]
        const list = parent.lastChild
        let elemInit = document.getElementById(`id-text-barrating-init-${question.inputId}`)
        if (elemInit) {
          elemInit.innerHTML = question.textoInicial
        } else {
          const newNodeInit = document.createElement('span')
          newNodeInit.id = `id-text-barrating-init-${question.inputId}`
          newNodeInit.className = 'text-barrating text-barrating-init'
          newNodeInit.innerHTML = question.textoInicial
          list.insertBefore(newNodeInit, list.children[0])
        }

        let elemEnd = document.getElementById(`id-text-barrating-end-${question.inputId}`)
        if (elemEnd) {
          elemEnd.innerHTML = question.textoFinalizacion
        } else {
          const newNodeEnd = document.createElement('span')
          newNodeEnd.id = `id-text-barrating-end-${question.inputId}`
          newNodeEnd.className = 'text-barrating text-barrating-end'
          newNodeEnd.innerHTML = question.textoFinalizacion
          list.insertBefore(newNodeEnd, null)
        }
      }
      var renderBarratingScale = function () {
        let parent = $customSelect.parents()[0]
        if (question.ratingScala === 'Escala5') {
          parent.classList.remove(`br-theme-scale-10`)
          parent.classList.add(`br-theme-scale-5`)
        }
        if (question.ratingScala === 'Escala10') {
          parent.classList.remove(`br-theme-scale-5`)
          parent.classList.add(`br-theme-scale-10`)
        }

      }
      var renderBarratingOrientation = function () {
        let parent = $customSelect.parents()[0]
        if (question.orientation === 'horizontal') {
          parent.classList.remove(`br-theme-vertical`)
          parent.classList.add(`br-theme-horizontal`)
        }
        if (question.orientation === 'vertical') {
          parent.classList.add(`br-theme-vertical`)
          parent.classList.remove(`br-theme-horizontal`)
        }
      }
      var renderBarratingColor = function () {
        let parent = $customSelect.parents()[0]
        if (question.colorScale === 'scale-gray') {
          parent.classList.add(`br-theme-scale-gray`)
        } else {
          parent.classList.remove(`br-theme-scale-gray`)
        }
      }
      renderCustomSelect()
      renderBarrating()
      renderBarratingText()
      renderBarratingScale()
      renderBarratingOrientation()
      renderBarratingColor()
      if (!!$customSelect.parents()[0])
        $customSelect.parents()[0].style.marginBottom = '3px'
      var valueChangingByWidget = false

      question.valueChangedCallback = function () {
        if (
          !valueChangingByWidget &&
          $(contentContainer).find('select.sv-widget-select')[0].value !==
          question.value
        ) {
          $(contentContainer).find('select.sv-widget-select').barrating('set', question.value)
        }
      }
      question.__barratingOnPropertyChangedCallback = function (
        sender,
        options,
      ) {
        let parent = $customSelect.parents()[0]
        if (options.name === 'ratingTheme') {
          question.showValues = false
          if (question.ratingTheme === 'css-numeros') {
            question.showValues = true
          }
          $customSelect.barrating('destroy')
          renderBarrating()
          renderBarratingText()
        }
        if (options.name === 'textoInicial' || options.name === 'textoFinalizacion') {
          renderBarratingText()
        }
        if (options.name === 'ratingScala') {
          if (question.ratingScala === 'Escala5') {
            question.choices = [1, 2, 3, 4, 5]
          }
          if (question.ratingScala === 'Escala10') {
            question.choices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          }
          renderBarratingScale()
        }
        if (options.name === 'orientation') {
          renderBarratingOrientation()
        }
        if (options.name === 'colorScale') {
          renderBarratingColor()
        }
      }
      question.onPropertyChanged.add(
        question.__barratingOnPropertyChangedCallback,
      )
      question.readOnlyChangedCallback = function () {
        removeCustomSelect()
        renderCustomSelect()
        renderBarrating()
        renderBarratingText()
        renderBarratingScale()
        renderBarratingOrientation()
        renderBarratingColor()
      }
      question.visibleChoicesChangedCallback = function () {
        removeCustomSelect()
        renderCustomSelect()
        renderBarrating()
        renderBarratingText()
        renderBarratingScale()
        renderBarratingOrientation()
        renderBarratingColor()
      }
    },
    willUnmount: function (question, el) {
      var $contentContainer = $(el).is('select')
        ? $(el).parent().parent()
        : $(el).parent()
      var $el = $contentContainer.find('select.sv-widget-select')
      $el.barrating('destroy')
      $el.remove()
      question.valueChangedCallback = undefined
      question.onPropertyChanged.remove(
        question.__barratingOnPropertyChangedCallback,
      )
      question.__barratingOnPropertyChangedCallback = undefined
    },
    pdfQuestionType: 'dropdown',
  }

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, 'customtype')
}

if (typeof Survey !== 'undefined') {
  init(Survey, window.$)
}

export default init
