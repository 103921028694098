import {Model} from "survey-core";
import {Survey} from "survey-react";
import {
    addEventPag,
    doOnCurrentPageChanged,
    handleChangePage,
    loadPageNumber,
    normalizeSurveyJSON,
    obtenerEncuestaFinal,
    setFinText,
    updateBotonesPagina
} from "../../../helpers/smartSurvey";
import {SetStyleKeyValue} from "../../../components/Common/Survey/Utils";
import { DEFAULT_GUID } from "../../../constants/default";

const SurveyRender = (props:any) => {
    let currentJson = normalizeSurveyJSON(props.surveyData, props.itemStyle, "PRODUCTO", "es")
    let currentModel = new Model(currentJson)
    currentModel.onAfterRenderQuestion.add((_surv: any, optionsPar: any) => {
        const parent: HTMLDivElement = optionsPar.htmlElement.parentElement;
        let minQuestion: string = (parent.style as any)['min-width'];
        let minRes: number = parseInt(minQuestion.replace("px", ""));
        (parent.style as any)['min-width'] = (minRes - 30).toString() + "px"
    });
    currentModel.locale = "es"
    window["survey"] = currentModel

    if (currentJson !== undefined) {
        let formulario = JSON.parse(currentJson)
        window["formulario"] = formulario

        if (formulario !== undefined && formulario["pageNumber"] !== undefined) {
            window["survey"].pageNumber = formulario["pageNumber"]
        } else
            window["survey"].pageNumber = 1
    }
    addEventPag("es")

    const onComplete = (result: any) => {
        SetStyleKeyValue("--display-title", "none")
        setTimeout(setFinText, 1000, obtenerEncuestaFinal(props.itemStyle, result.data,DEFAULT_GUID))
    }
    const onStarted = () => {
        $('#contenedor-encuesta').removeClass('container-gr').removeClass('centerInicio').addClass('pregunta-oculta')
        $('.sd-root-modern').removeClass('sd-root-modern--mobile')
        let surveyInternalNext = (document.getElementById('survey_page_next') as HTMLElement)
        if (surveyInternalNext) {
            surveyInternalNext.style.display = "inline";
        }
        loadPageNumber()
        window["count"] = window["survey"].pageNumber - 1
        updateBotonesPagina()
        setTimeout(handleChangePage, 1, -1, window["count"])
        SetStyleKeyValue("--display-title", "")
    }

    const eventos = {
        onComplete: onComplete,
        onStarted: onStarted
    }

    return (
        <>
            <Survey
                model={currentModel}
                onCurrentPageChanged={doOnCurrentPageChanged}
                onComplete={eventos.onComplete} onStarted={eventos.onStarted}
                className={props.itemStyle && props.itemStyle.tabStyle}
            />
        </>
    )
}
export default SurveyRender