import HorizontalLayout from "../components/HorizontalLayout";

export const LayoutFormulario = ({Component, ...props}: any) => {
    return (
        <HorizontalLayout styleMenu="sidebarMenuMin">
            <Component {...props}/>
        </HorizontalLayout>

    )

}

